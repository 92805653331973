import React, { useEffect, useState } from 'react'

// Routes
import { Rest } from './Rest'
import { Authentificated } from './Authentificated'
import { useAuth } from '../context/AuthProvider'
import { MainLayout } from '../layouts/MainLayout'
import { useLocation } from 'react-router-dom'
import { ActivityWarningModal } from '../elements/Modal/ActivityWarningModal'
import { ACTIVITY_EXPIRATION_WARNING } from '../utils/Constants'


export const AppRoutes = () => {

  // Transfer env to boolean
  const { token, logout, activityExpiration } = useAuth()
  const [logged, setLogged] = useState(false)
  const [checked, setChecked] = useState(false)
  const location = useLocation();
  const activityExpired = new Date(activityExpiration) < new Date()
  const [activityTimer, setActivityTimer] = useState(null)
  const [warningOpen, setWarningOpen] = useState(false)

  useEffect(() => {
    setChecked(true)
    if (token) {
      const tokenExpired = !token.expirationDate || new Date(token.expirationDate) < new Date()
      setLogged(!(tokenExpired || activityExpired))
    }
  }, [token, activityExpired])

  useEffect(() => {
    if (checked && !logged) {
      logout()
      setWarningOpen(false)
    }
  }, [logged, checked])

  useEffect(() => {
    if (activityTimer) {
      clearTimeout(activityTimer)
      setActivityTimer(null)
    }
    const date = new Date(activityExpiration)
    date.setSeconds(date.getSeconds() - ACTIVITY_EXPIRATION_WARNING)
    const expireSeconds = (date.getTime() - new Date().getTime()) / 1000
    if (expireSeconds > ACTIVITY_EXPIRATION_WARNING) {
      setActivityTimer(
        setTimeout(() => {
          setWarningOpen(true)
        }, expireSeconds * 1000)
      )
    }
  }, [activityExpiration])


  if (!checked) {
    // Page during authentificating
    return (
      <MainLayout withoutShadow>Checking Auth</MainLayout>
    )
  }

  if (logged && location.pathname !== '/auth/reset') {
    // Pages when user is logged
    return (
      <>
        <Authentificated />
        <ActivityWarningModal visible={warningOpen} afterClose={() => setWarningOpen(false)} />
      </>)
  } else {
    // Auth pages
    return <Rest />
  }
}
