import { useForm } from 'antd/lib/form/Form'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { device } from '../../style/breakpoints/device'
import { Button, Col, Form, Input, Row, Tabs, notification, InputNumber } from 'antd'
import { useTranslation } from 'react-i18next'
import { postToAPI } from '../../context/API'
import { LANGUAGE_ID_MAP } from '../../utils/Constants'
import { openNotificationWithIcon } from '../../utils/Notification'
import { useAuth } from '../../context/AuthProvider'
import PhoneInput from '../Input/PhoneInput'

export const ManufacturerForm = props => {
  const { values, editMode, onSubmit } = props
  const [updatingManufacturer, setUpdatingManufacturer] = useState(false)
  const [form] = useForm()
  const { t } = useTranslation()
  const auth = useAuth()

  const onFinish = formValues => {
    setUpdatingManufacturer(true)
    if (editMode) {
      formValues['intManufacturerID'] = values['intManufacturerID']
    }
    postToAPI('item/editManufacturer', formValues, false, auth)
      .then(res => res.json())
      .then(data => {
        setUpdatingManufacturer(false)
        if (onSubmit) {
          onSubmit(data)
        }
      })
      .catch(err => {
        setUpdatingManufacturer(false)
        openNotificationWithIcon(
          'error',
          t('manufacturers.onFail.title'),
          t('manufacturers.onFail.description')
        )
      })
  }

  useEffect(() => {
    if (Object.keys(values).length > 0) {
      form.setFieldsValue(values)
    }
  }, [values])

  return (
    <FormWrapper>
      <Form
        name='normal_edit_manufacturer'
        onFinish={onFinish}
        size='large'
        form={form}
        preserve={false}
        validateTrigger='onSubmit'
        labelCol={{ flex: '130px' }}
        labelAlign='right'
        colon={false}
      >
        <Form.Item
          name='strCompany'
          rules={[{ required: true, message: '' }]}
          label={t('manufacturers.company')}
        >
          <Input type='text' />
        </Form.Item>
        <Form.Item name='strName' label={t('manufacturers.name')}>
          <Input type='text' />
        </Form.Item>
        <Form.Item
          name='strAddress1'
          rules={[{ required: true, message: '' }]}
          label={t('manufacturers.address')}
        >
          <Input type='text' />
        </Form.Item>
        <Form.Item name='strAddress2' label={t('manufacturers.address2')}>
          <Input type='text' />
        </Form.Item>
        <Form.Item
          name='strZIP'
          rules={[{ required: true, message: '' }]}
          label={t('manufacturers.zip')}
        >
          <InputNumber type="number" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='strCity'
          rules={[{ required: true, message: '' }]}
          label={t('manufacturers.city')}
        >
          <Input type='text' />
        </Form.Item>
        <Form.Item name='strState' label={t('manufacturers.state')}>
          <Input type='text' />
        </Form.Item>
        <Form.Item name='strCountry' label={t('manufacturers.country')}>
          <Input type='text' />
        </Form.Item>
        <Form.Item name='strPhone' label={t('manufacturers.phone')}>
          <PhoneInput />
        </Form.Item>
        <Form.Item name='strEMail' label={t('manufacturers.email')}
          rules={[
            {
              type: 'email',
              message: t('manufacturers.notValidEmail')
            },
          ]}
        >
          <Input type='text' />
        </Form.Item>
        <Form.Item name='strWeb' label={t('manufacturers.web')}>
          <Input type='text' />
        </Form.Item>
        <Row justify='center'>
          <Col span={24}>
            <Row justify='end'>
              <Form.Item>
                <Button htmlType='submit' disabled={updatingManufacturer}>
                  {editMode
                    ? t('manufacturers.confirmChanges')
                    : t('manufacturers.addManufacturer')}
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </FormWrapper>
  )
}

const FormWrapper = styled.div`
  .ant-modal-body {
    height: fit-content;
    @media ${device.tablet} {
      height: initial;
    }
  }
`
