import React, { useState } from 'react'
import { Form, Input, Button, notification } from 'antd';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { device } from '../../style/breakpoints/device';
import { MailOutlined } from '@ant-design/icons';
import logo from '../../assets/img/logo.svg'
import { Link } from 'react-router-dom';
import { postToAPI } from '../../context/API';
import { openNotificationWithIcon } from '../../utils/Notification';

export const ForgotPassword = () => {
  const { t } = useTranslation()
  const [sendEnabled, setSendEnabled] = useState(true)

  const onFinish = (values) => {
    const { email } = values
    setSendEnabled(false)

    postToAPI('auth/forgotPassword', { email: email, domain: window.location.origin }).then(res => {
      openNotificationWithIcon('info', t('forgotPassword.onSend.title'), t('forgotPassword.onSend.description'))
    }).catch(err => console.log(err))
  };

  return (
    <PageContainer>
      <ForgotPasswordForm
        name="normal_forgot_password"
        className="login-form"
        onFinish={onFinish}
        size="large"
        validateTrigger='onSubmit'
      >
        <ImgWrapper>
          <StyledImg src={logo} />
        </ImgWrapper>
        <StyledMessage>{t('forgotPassword.message')}</StyledMessage>
        <Form.Item
          name="email"
          rules={[{ type: "email", required: true, message: '' }]}
        >
          <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder={t('login.emailPlaceholder')} />
        </Form.Item>
        <Form.Item>
          <StyledLink to='/auth/login'>
            {t('forgotPassword.backToLogin')}
          </StyledLink>
        </Form.Item>
        <Form.Item style={{ float: 'right' }}>
          <SubmitButton type="primary" htmlType="submit" className="login-form-button" disabled={!sendEnabled} >
            {t('forgotPassword.sendButton')}
          </SubmitButton>
        </Form.Item>
      </ForgotPasswordForm>
    </PageContainer>
  )
}

const StyledMessage = styled.p`
  @media ${device.tablet} {
    width: 350px;
    max-width: 350px;
  }
`

const SubmitButton = styled(Button)`
  background-color: ${props => props.theme.colors.primary};
  border-color: ${props => props.theme.colors.primary};
  align-self: center;
  &:active{
    background-color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
    color: #fff;
  }
  &:hover{
    background-color: #fff;
    border-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
  }
  &:focus{
    background-color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
    color: #fff;
    &:hover{
      background-color: #fff;
      border-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.primary};
    }
  }
`
const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.primary};
  &:hover{
    color: ${props => props.theme.colors.primary};
  }
`

const ForgotPasswordForm = styled(Form)`
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 50px;
  height: 100%;
  width: 100%;
  @media ${device.tablet} {
    padding: 100px;
    height: fit-content;
    width: fit-content;
  }
`
const PageContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`
const StyledImg = styled.img`
  width: 100%;
  max-width: 300px;
  min-width: 150px;
`
