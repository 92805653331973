import React, { useState, useEffect } from 'react';
import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';


const Tooltip = ({ content, breakpoint = 992, placement="bottom" }) => {
  const [trigger, setTrigger] = useState("hover")

  useEffect(() => {
    window.addEventListener('resize', function(event) {
      if((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) > breakpoint && trigger !== "hover") {
        setTrigger("hover")
      }
      if((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= breakpoint && trigger !== "click") {
        setTrigger("click")
      }
    }, true);
  }, [])

  return (
    <Popover trigger={trigger} placement={placement} content={content}>
      <InfoCircleOutlined />
    </Popover>
  )
}

export default Tooltip;