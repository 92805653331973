import React, { useEffect, useState } from 'react'
import { Space, Table, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import OrderItemQuantityModal from '../Modal/OrderItemQuantityModal';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    body {
      overflow-y: scroll;
    }
  `
  
export const OrderItemTable = (props) => {
  const { t } = useTranslation();
  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState()
  const { dataSource, onQtyChange, onRemoveItem, pagination, orderTypes, onItemUpdate, reservation, priceList, roomNumbers } = props

  useEffect(() => {
    if (dataSource) {
      setItems(dataSource)
    }
  }, [dataSource])


  const handleOk = (values) => {
    onItemUpdate(selectedItem, values)
    setSelectedItem()
  }

  const columns = [
    {
      title: t('items.itemNumber'),
      dataIndex: 'strItemNo',
      sorter: (a, b) => a.strItemNo && a.strItemNo.localeCompare(b.strItemNo)
    },
    {
      title: t('items.name'),
      dataIndex: 'name',
      sorter: (a, b) => a.name && a.name.localeCompare(b.name),
      responsive: ["md"]
    },
    {
      title: t('items.quantity'),
      dataIndex: 'decQty',
      sorter: (a, b) => a.decQty - b.decQty,
      render: (text, record) => (
        onQtyChange ? (
          <Space size="small">
            <InputNumber min={1} value={record.decQty} onChange={value => onQtyChange(record, value)} />
          </Space>) :
          (text)
      ),
    },
    {
      title: t('orders.room'),
      dataIndex: 'strRoom',
      sorter: (a, b) => a.strRoom && a.strRoom.localeCompare(b.strRoom),
      responsive: ["md"]
    },
    {
      title: t('orders.orderType'),
      dataIndex: 'orderType',
      sorter: (a, b) => a.orderType && a.orderType.localeCompare(b.orderType),
      responsive: ["md"]
    },
    {
      title: t('items.actions'),
      key: 'action',
      className: 'actions-column',
      render: (_, record) => (
        <Space size="small">
          <EditOutlined onClick={() => setSelectedItem(record)} />
          <DeleteOutlined onClick={() => onRemoveItem(record)} />
        </Space>
      ),
    }
  ];
  if (priceList) {
    columns.push({
      title: t('orders.price'),
      sorter: (a, b) => a.orderType && a.orderType.localeCompare(b.orderType),
      responsive: ["md"],
      render: (_, record, index) => {
        const price = priceList?.prices?.find(item => item.source === record)?.decPrice || ''
        // const price = priceList?.prices && priceList?.prices[index]?.decPrice || ''
        return (<Space size="small">
          {(price?.toFixed && price.toFixed(2)) || '-'}
        </Space>)
      }
    })
    columns.push({
      title: t('orders.total'),
      sorter: (a, b) => a.orderType && a.orderType.localeCompare(b.orderType),
      responsive: ["md"],
      render: (_, record, index) => {
        const price = priceList?.prices?.find(item => item.source === record)?.decAmount || ''
        return (<Space size="small">
          {(price?.toFixed && price.toFixed(2)) || '-'}
        </Space>)
      }
    })
  }

  return (
    <>
      <Table columns={columns} pagination={pagination} dataSource={items} showSorterTooltip={false} scroll={{ x: "scroll"}} {...props} />
      <GlobalStyles />
      <OrderItemQuantityModal
        title={t('items.editItem')}
        okText={t('items.confirmChanges')}
        cancelText={t('modals.cancel')}
        visible={selectedItem}
        values={selectedItem}
        orderTypes={orderTypes}
        reservation={reservation}
        onCancel={() => setSelectedItem()}
        onOk={(values) => handleOk(values)}
        roomNumbers={roomNumbers}
      />
    </>
  )
}
