
/**
 * 
 * @param {object} data  => order or reservation data
 * @param {string} costCenter => cost center
 * @returns Filtered data
 */
export const filterDataByCostCenter = (data, costCenter) => {
  if (data.length === 0 || !costCenter || costCenter === '-') {
    return data;
  }
  return data.filter(c => c.strCostCenter === costCenter);
};