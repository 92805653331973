import React, { useState } from 'react'
import { Form, Input, Button, notification } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { device } from '../../style/breakpoints/device'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import logo from '../../assets/img/logo.svg'
import { Link } from 'react-router-dom'
import { useAuth } from '../../context/AuthProvider'
import { openNotificationWithIcon } from '../../utils/Notification'

export const Login = () => {
  const { t } = useTranslation()
  const auth = useAuth()

  const [loginEnabled, setLoginEnabled] = useState(true)

  const onFinish = async values => {
    setLoginEnabled(false)
    const result = await auth.login(values['email'], values['password'])
    if (result === 401) {
      openNotificationWithIcon(
        'error',
        t('login.loginError.title'),
        t('login.loginError.description')
      )
    } else if (result === 500){
      openNotificationWithIcon(
        'error',
        t('login.loginError.title'),
        t('login.loginError.serverError')
      )
    }
    setLoginEnabled(true)
  }

  return (
    <PageContainer>
      <LoginForm
        name='normal_login'
        className='login-form'
        initialValues={{ remember: true }}
        onFinish={onFinish}
        size='large'
      >
        <ImgWrapper>
          <StyledImg src={logo} />
        </ImgWrapper>
        <StyledMessage>{t('login.message')}</StyledMessage>
        <Form.Item
          name='email'
          rules={[{ type: 'email', required: true, message: t('users.notValidEmail') }]}
        >
          <Input
            prefix={<UserOutlined className='site-form-item-icon' />}
            placeholder={t('login.emailPlaceholder')}
          />
        </Form.Item>
        <Form.Item name='password' rules={[{ required: true, message: '' }]}>
          <Input
            prefix={<LockOutlined className='site-form-item-icon' />}
            type='password'
            placeholder={t('login.passwordPlaceholder')}
          />
        </Form.Item>
        <Form.Item>
          <StyledLink to='/auth/forgot'>{t('login.forgotPassword')}</StyledLink>
        </Form.Item>
        <Form.Item style={{ float: 'right' }}>
          <SubmitButton
            disabled={!loginEnabled}
            type='primary'
            htmlType='submit'
            className='login-form-button'
          >
            {t('login.loginButton')}
          </SubmitButton>
        </Form.Item>
      </LoginForm>
    </PageContainer>
  )
}

const StyledMessage = styled.p`
  @media ${device.tablet} {
    width: 350px;
    max-width: 350px;
    text-align: center;
  }
`

const SubmitButton = styled(Button)`
  background-color: ${props => props.theme.colors.primary} !important;
  border-color: ${props => props.theme.colors.primary} !important;
  align-self: center !important;
  &:active {
    background-color: ${props => props.theme.colors.primary} !important;
    border-color: ${props => props.theme.colors.primary} !important;
    color: #fff !important;
  }
  &:hover {
    background-color:${props => props.theme.colors.primary ? '#fff !important' : ''};
    border-color: ${props => props.theme.colors.primary} !important;
    color: ${props => props.theme.colors.primary} !important;
  }
  &:focus {
    background-color: ${props => props.theme.colors.primary} !important;
    border-color: ${props => props.theme.colors.primary} !important;
    color: #fff !important;
    &:hover {
      background-color: #fff !important;
      border-color: ${props => props.theme.colors.primary} !important;
      color: ${props => props.theme.colors.primary} !important;
    }
  }
  &:disabled {
    background-color: #fff !important;
    border-color: ${props => props.theme.colors.primary} !important;
    color: ${props => props.theme.colors.primary} !important;
    opacity: 0.25 !important;
    &:hover, &:focus, &:active {
      background-color: #fff !important;
      border-color: ${props => props.theme.colors.primary} !important;
      color: ${props => props.theme.colors.primary} !important;
    }
`
const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.primary};
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`

const LoginForm = styled(Form)`
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 50px;
  height: 100%;
  width: 100%;
  @media ${device.tablet} {
    padding: 100px;
    height: fit-content;
    width: fit-content;
  }
`
const PageContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`
const StyledImg = styled.img`
  width: 100%;
  max-width: 300px;
  min-width: 150px;
`
