import { useForm } from 'antd/lib/form/Form'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { device } from '../../style/breakpoints/device'
import { Button, Col, Form, Input, Row, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import { postToAPI } from '../../context/API'
import { LANGUAGE_ID_MAP } from '../../utils/Constants'
import { openNotificationWithIcon } from '../../utils/Notification'
import { useAuth } from '../../context/AuthProvider'

export const CategoryForm = props => {
  const { values, editMode, onSubmit } = props
  const [updatingCategory, setUpdatingCategory] = useState(false)
  const [form] = useForm()
  const { t } = useTranslation()
  const auth = useAuth()

  const onFinish = formValues => {
    setUpdatingCategory(true)
    const reqValues = {}
    reqValues['strNo'] = formValues['strNo']
    const names = []
    formValues['enName'] &&
      names.push({ intLanguageID: 1, strText: formValues['enName'] })
    formValues['deName'] &&
      names.push({ intLanguageID: 2, strText: formValues['deName'] })
    formValues['frName'] &&
      names.push({ intLanguageID: 3, strText: formValues['frName'] })
    formValues['itName'] &&
      names.push({ intLanguageID: 4, strText: formValues['itName'] })
    reqValues['strName'] = names
    if (editMode) {
      reqValues['intItemCatID'] = values['intItemCatID']
    }
    postToAPI('item/editCategory', reqValues, false, auth)
      .then(res => res.json())
      .then(data => {
        setUpdatingCategory(false)
        if (onSubmit) {
          onSubmit(data)
        }
      })
      .catch(err => {
        setUpdatingCategory(false)
        openNotificationWithIcon(
          'error',
          t('categories.onFail.title'),
          t('categories.onFail.description')
        )
      })
  }

  useEffect(() => {
    if (Object.keys(values).length > 0) {
      const formValues = {}
      formValues['strNo'] = values['strNo']
      values.strName.forEach(name => {
        const lang = Object.entries(LANGUAGE_ID_MAP).find(
          obj => obj[1] === name.intLanguageID
        )
        if (lang) {
          const langPrefix = lang[0]
          formValues[`${langPrefix}Name`] = name.strText
        }
      })
      form.setFieldsValue(formValues)
    }
  }, [values])

  const items = [
    {
      key: '1',
      label: 'de',
      children: (
        <Form.Item name='deName'>
          <Input type='text' />
        </Form.Item>
      ),
    },
    {
      key: '2',
      label: 'en',
      children: (
        <Form.Item name='enName'>
          <Input type='text' />
        </Form.Item>
      ),
    },
    {
      key: '3',
      label: 'fr',
      children: (
        <Form.Item name='frName'>
          <Input type='text' />
        </Form.Item>
      ),
    },
    {
      key: '4',
      label: 'it',
      children: (
        <Form.Item name='itName'>
          <Input type='text' />
        </Form.Item>
      ),
    },
  ]

  return (
    <FormWrapper>
      <Form
        name='normal_edit_category'
        onFinish={onFinish}
        size='large'
        form={form}
        preserve={false}
        validateTrigger='onSubmit'
        labelCol={{ flex: '80px' }}
        labelAlign='right'
        colon={false}
      >
        <Form.Item
          name='strNo'
          label={t('categories.categoryNumber')}
          rules={[{ required: true, message: '' }]}
        >
          <Input type='text' />
        </Form.Item>
        <Form.Item
          label={t('categories.name')}
          name='name'
          rules={[
            {
              message: t('categories.nameWarning'),
              required: true,
              validator: (_, value) => {
                const values = form.getFieldsValue()
                const enName = values['enName']
                const deName = values['deName']
                const frName = values['frName']
                const itName = values['itName']
                if (enName || deName || frName || itName) {
                  return Promise.resolve()
                } else {
                  return Promise.reject()
                }
              }
            }
          ]}
        >
          <Tabs defaultActiveKey='1' type='card' size='small' items={items}/>
        </Form.Item>
        <Row justify='center'>
          <Col span={24}>
            <Row justify='end'>
              <Form.Item>
                <Button htmlType='submit' disabled={updatingCategory}>
                  {editMode
                    ? t('categories.confirmChanges')
                    : t('categories.addCategory')}
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </FormWrapper>
  )
}

const FormWrapper = styled.div`
  .ant-modal-body {
    height: fit-content;
    @media ${device.tablet} {
      height: initial;
    }
  }
`
