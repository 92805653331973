import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { AuthProvider } from './AuthProvider'
import { defaultTheme, GlobalStyles } from '../style/theme/default'
import ErrorBoundary from '../layouts/ErrorBoundary';
import { CompanyProvider, useCompany } from './CompanyProvider';
import { CookieConsentProvider } from './CookieConsentProvider';
import { DynamicThemeProvider } from '../style/theme/default/DynamicThemeProvider';


export const AppProviders = (props) => {
  const { children } = props
  return (
    <CompanyProvider>
      <DynamicThemeProvider>
        <CookieConsentProvider>
          <AuthProvider>
            <GlobalStyles />
            <ErrorBoundary>
              {children}
            </ErrorBoundary>
          </AuthProvider>
        </CookieConsentProvider>
      </DynamicThemeProvider>
    </CompanyProvider>
  )
}
