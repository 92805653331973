import React, { useMemo, useState } from 'react'
import { Form, Input, Button, Modal, Select } from 'antd';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { device } from '../../style/breakpoints/device';
import { LockOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { SUPPORTEDLNGS } from '../../utils/Constants';

const ResetPasswordModal = (props) => {
  const { t, i18n } = useTranslation();
  const { visible, onOk, onCancel, confirmLoading } = props
  const [form] = useForm()
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const handleSubmit = () => {
    form.submit()
  }

  const setLang = () => {
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('wfLng', selectedLanguage);
  }

  const onFinish = (values) => {
    onOk(values)
  }

  const options = useMemo(() => SUPPORTEDLNGS.map((value) => ({
    label: t(`languages.${value}`),
    value
  })), [i18n.language])

  return (
    <Modal
      title={t('header.preferences')}
      open={visible}
      onCancel={onCancel}
      cancelText={t('modals.cancel')}
      destroyOnClose
      confirmLoading={confirmLoading}
      footer={null}
    >
      <Form
        name="normal_reset_password"
        className="login-form"
        onFinish={onFinish}
        size="large"
        form={form}
        preserve={false}
        validateTrigger='onSubmit'
      >
        <StyledTitle>{t('changePassword.title')}</StyledTitle>
        <StyledMessage>{t('changePassword.message')}</StyledMessage>
        <Form.Item
          name="password"
          rules={[{ required: true, message: '' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={t('changePassword.passwordPlaceholder')}
          />
        </Form.Item>
        <Form.Item
          name="newPassword"
          rules={[{ required: true, message: '' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={t('changePassword.newPasswordPlaceholder')}
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={['newPassword']}
          rules={[
            {
              required: true,
              message: '',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(''));
              },
            }),
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={t('changePassword.confirmPasswordPlaceholder')}
          />
        </Form.Item>
        <ButtonContainer>
          <Button type='primary' size='middle' onClick={handleSubmit}>{t('modals.ok')}</Button>
        </ButtonContainer>
      </Form>
      <LanguageSwitcher>
        <StyledTitle>{t('changeLanguage.title')}</StyledTitle>
        <StyledMessage>{t('changeLanguage.message')}</StyledMessage>
        <Select
          options={options}
          style={{
            width: "100%",
            marginBottom: 24
          }}
          onChange={setSelectedLanguage}
          value={selectedLanguage}
          defaultValue={i18n.language}
        />
        <ButtonContainer>
          <Button disabled={selectedLanguage === i18n.language} type='primary' size='middle' onClick={setLang}>{t('modals.ok')}</Button>
        </ButtonContainer>
      </LanguageSwitcher>
    </Modal>
  )
}

const StyledMessage = styled.p`
  @media ${device.tablet} {
    width: 350px;
    max-width: 350px;
  }
`
const StyledTitle = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`

const LanguageSwitcher = styled.div`
  margin-top: 24px;
`

export default ResetPasswordModal