import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MainLayout } from '../../layouts/MainLayout'
import { Button, Popconfirm, Space, Table } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import ClientModal from '../../elements/Modal/ClientModal'
import styled from 'styled-components'
import { getFromAPI, postToAPI } from '../../context/API'
import { openNotificationWithIcon } from '../../utils/Notification'
import { useAuth } from '../../context/AuthProvider'

export const Clients = () => {
  const { t } = useTranslation()
  const [visibleConfirm, setVisibleConfirm] = useState({})
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [editLoading, setEditLoading] = useState(false)
  const [visibleCreate, setVisibleCreate] = useState(false)
  const [isAddBtnDisabled, setIsAddBtnDisabled] = useState(false)
  const [createLoading, setCreateLoading] = useState(false)
  const [clients, setClients] = useState([])
  const auth = useAuth()

  const getClients = () => {
    getFromAPI('client/getAll', { admin: true }, auth)
      .then(res => res.json())
      .then(data => {
        if (data.code === 401) {
          setIsAddBtnDisabled(true)
        }
        setClients(
          data.response.map((client, index) => {
            client.key = index
            client.mainColor =
              client.mainColor !== 'undefined' ? client.mainColor : ''
            client.secondColor =
              client.secondColor !== 'undefined' ? client.secondColor : ''
            client.fontColor =
              client.fontColor !== 'undefined' ? client.fontColor : ''
            return client
          })
        )
      })
  }

  useEffect(() => {
    getClients()
  }, [])

  const handleCreateClient = async values => {
    setCreateLoading(true)
    const data = new FormData()
    data.append('admin', true)
    data.append('slugName', values.slugName)
    data.append('apiKey', values.apiKey)
    data.append('company', values.name)
    data.append('mainColor', values.mainColor)
    data.append('secondaryColor', values.secondColor)
    data.append('fontColor', values.fontColor)
    data.append('greeting', JSON.stringify(values.greeting))
    data.append('isItemThumbnailVisible', values.isItemThumbnailVisible)
    data.append('isItemsPriceVisible', values.isItemsPriceVisible)
    data.append('isDashboardVisible', values.isDashboardVisible)
    data.append('statisticsUrl', values.statisticsUrl)
    if (values.logo) {
      data.append('logo', values.logo)
    }
    await postToAPI('client/create', data, true, auth)
      .then(res => res.json())
      .then(data => {
        if (data.error) {
          setCreateLoading(false)
          openNotificationWithIcon(
            'error',
            t('clients.onExists.title'),
            t('clients.onExists.message')
          )
        } else {
          setCreateLoading(false)
          setVisibleCreate(false)
          getClients()
        }
      })
      .catch(err => console.log(err))
  }

  const handleSaveClient = async values => {
    setEditLoading(true)
    const data = new FormData()
    data.append('admin', true)
    data.append('slugName', values.slugName)
    data.append('apiKey', values.apiKey)
    data.append('company', values.name)
    data.append('mainColor', values.mainColor)
    data.append('secondaryColor', values.secondColor)
    data.append('fontColor', values.fontColor)
    data.append('greeting', JSON.stringify(values.greeting))
    data.append('isItemThumbnailVisible', values.isItemThumbnailVisible)
    data.append('isItemsPriceVisible', values.isItemsPriceVisible)
    data.append('isDashboardVisible', values.isDashboardVisible)
    data.append('statisticsUrl', values.statisticsUrl)
    if (values.logo) {
      data.append('logo', values.logo)
    }
    await postToAPI('client/edit', data, true, auth)
      .then(res => res.json())
      .then(data => {
        if (data.error) {
          setEditLoading(false)
          openNotificationWithIcon(
            'error',
            t('clients.onNotExists.title'),
            t('clients.onNotExists.message')
          )
        } else {
          setEditLoading(false)
          setVisibleEdit(false)
          getClients()
        }
      })
      .catch(err => console.log(err))
  }

  const handleRemove = async client => {
    setConfirmLoading(true)
    const data = new FormData()
    data.append('admin', true)
    data.append('slugName', client.slugName)
    await postToAPI('client/delete', data, true, auth)
      .then(res => res.json())
      .then(data => {
        if (data.error) {
          setConfirmLoading(false)
          openNotificationWithIcon(
            'error',
            t('clients.onNotExists.title'),
            t('clients.onNotExists.message')
          )
        } else {
          setConfirmLoading(false)
          handleVisibleConfirm(client.slugName, false)
          getClients()
        }
      })
      .catch(err => console.log(err))
  }

  const handleVisibleConfirm = (slugName, visibility) => {
    setVisibleConfirm({ ...visibleConfirm, [slugName]: visibility })
  }

  const columns = [
    {
      title: t('clients.client'),
      dataIndex: 'name',
      key: 'name',
      render: text => <p>{text}</p>
    },
    {
      title: t('clients.slugName'),
      dataIndex: 'slugName',
      key: 'slugName',
      render: text => <p>{text}</p>
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <EditOutlined
            onClick={() => {
              setVisibleEdit(record)
            }}
          />
          <Popconfirm
            title={t('clients.onRemove.message')}
            okText={t('clients.onRemove.remove')}
            cancelText={t('clients.cancel')}
            open={visibleConfirm[record.slugName]}
            onConfirm={() => handleRemove(record)}
            okButtonProps={{
              loading: confirmLoading
            }}
            onCancel={() => handleVisibleConfirm(record.slugName, false)}
          >
            <DeleteOutlined
              onClick={() =>
                handleVisibleConfirm(
                  record.slugName,
                  !visibleConfirm[record.slugName]
                )
              }
            />
          </Popconfirm>
        </Space>
      )
    }
  ]

  return (
    <MainLayout>
      <div>
        <ClientModal
          visible={visibleCreate}
          onOk={handleCreateClient}
          onCancel={() => {
            setVisibleCreate(false)
          }}
          confirmLoading={createLoading}
          title={t('clients.createClient')}
        />
        <ClientModal
          visible={visibleEdit}
          record={visibleEdit}
          onOk={handleSaveClient}
          onCancel={() => {
            setVisibleEdit(false)
          }}
          confirmLoading={editLoading}
          title={t('clients.editClient')}
          editMode
        />
        {isAddBtnDisabled === false && (
          <StyledSpace>
            <Button
              onClick={() => {
                setVisibleCreate(true)
              }}
            >
              {t('clients.addClient')}
            </Button>
          </StyledSpace>
        )}
        <Table columns={columns} dataSource={clients} scroll={{ x: "scroll"}} />
      </div>
    </MainLayout>
  )
}

const StyledSpace = styled.div`
  margin-bottom: 16px;
`
