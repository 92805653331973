const LINK_METHOD_MAP = {
  "/orders/create": "EditOrder",
  "/reservations/create": "EditOrder",
  "/reservations": "EditOrder",
  "/orders": "GetOrder",
  "/addresses": "GetAddress",
  "/editAddress": "EditAddress",
  "/items": "GetItem",
  "/items/create": "EditItem",
  "/groups": "GetItemGroup",
  "/editGroup": "EditItemGroup",
  "/categories": "GetItemCategory",
  "/editCategory": "EditItemCategory",
  "/manufacturers": "GetManufacturer",
  "/editManufacturer": "EditManufacturer",
  "/users": "GetUserList",
  "/updateUser": "UpdateUser",
  "/addUser": "Register",
  "/clients": "GetClients",
}

const LINK_MAP = {
  "/orders/create": "EditOrder",
  "/orders": "GetOrder",
  "/reservations/create": "EditOrder",
  "/reservations": "EditOrder",
  "/items": "GetItem",
  "/items/create": "EditItem",
  "/addresses": "GetAddress",
  "/groups": "GetItemGroup",
  "/manufacturers": "GetManufacturer",
  "/categories": "GetItemCategory",
  "/users": "GetUserList",
  "/clients": "GetClients",
}

export const hasAccessToRoute = (route, auth) => {
  const routeMethod = LINK_METHOD_MAP[route]
  return auth.token && auth.token.accessList && auth.token.accessList.includes(routeMethod)
}

// Route is optional, if not provided, will return first available link
export const getFirstLink = (auth, route) => {
  if(route && hasAccessToRoute(route, auth)) {
    return route
  }
  if(auth.token && auth.token.accessList && auth.token.accessList) {
    const item = Object.entries(LINK_MAP).find(([_, item]) => auth.token.accessList.includes(item))
    if(item) {
      return item[0]
    }
  }
}

// export const hasAccessToRoute = (route, auth) => {
//   const routeMethod = LINK_METHOD_MAP[route]
//   return auth.token && auth.token.accessList && auth.token.accessList.includes(routeMethod)
// }

export const READ_ONLY_ROLES = [5]