import React from 'react'
import ReservationForm from '../../elements/Form/ReservationForm'
import { MainLayout } from '../../layouts/MainLayout'

export const AddReservation = () => {
  return (
    <MainLayout>
      <ReservationForm />
    </MainLayout>
  )
}
