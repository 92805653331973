import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthProvider'
import { useCompany } from '../context/CompanyProvider';
import { Addresses } from '../pages/Adresses/page'
import { Categories } from '../pages/Categories/page'
import { Clients } from '../pages/Clients/page'
import { Dashboard } from '../pages/Dashboard/page'
import { Groups } from '../pages/Groups/page'
import { AddItem } from '../pages/Items/AddItem'
import { Items } from '../pages/Items/Items'
import { Manufacturers } from '../pages/Manufacturers/page'
import { AddOrder } from '../pages/Orders/AddOrder'
import { AddReservation } from '../pages/Orders/AddReservation'
import { Orders } from '../pages/Orders/Orders'
import { Reservations } from '../pages/Orders/Reservations'
import { Users } from '../pages/Users/page'
import { hasAccessToRoute, getFirstLink } from '../utils/RoleUtils'
import { Spin } from 'antd';
import { MainLayout } from '../layouts/MainLayout';

const HomePage = ({isDashboardVisible, auth}) => {
  if (isDashboardVisible.value === undefined || isDashboardVisible.updated === false) {
    return <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}><Spin /></div>
  }

  if(isDashboardVisible.value) {
    return <Dashboard />
  }
  return <MainLayout><div></div></MainLayout>
}

const UserRoutes = () => {
  const auth = useAuth()
  const company = useCompany()
  const navigate = useNavigate()
  const [isDashboardVisible, setIsDashboardVisible] = useState({ value: company.data && company.data.isDashboardVisible ? !!company.data.isDashboardVisible : undefined, updated: false })
  
  useEffect(() => {
    setIsDashboardVisible({ value: company.data && company.data.isDashboardVisible, updated: true })
    if(company.data && company.data.isDashboardVisible !== undefined) {
      const url = getFirstLink(auth)
      if(company.data && company.data.isDashboardVisible) {
        navigate("/")
        return;
      }
      url && navigate(url)
    }
  }, [company.data])
  

  return (
    <Routes>
      {hasAccessToRoute('/items/create', auth) && <Route path='/items/create' element={<AddItem />} />}
      {hasAccessToRoute('/items', auth) && <Route path='/items' element={<Items />} />}
      {hasAccessToRoute('/orders', auth) && <Route path='/orders' element={<Orders />} />}
      {hasAccessToRoute('/reservations', auth) && <Route path='/reservations' element={<Reservations />} />}
      {hasAccessToRoute('/orders/create', auth) && <Route path='/orders/create' element={<AddOrder />} />}
      {hasAccessToRoute('/reservations/create', auth) && <Route path='/reservations/create' element={<AddReservation />} />}
      {hasAccessToRoute('/groups', auth) && <Route path='/groups' element={<Groups />} />}
      {hasAccessToRoute('/categories', auth) && <Route path='/categories' element={<Categories />} />}
      {hasAccessToRoute('/manufacturers', auth) && <Route path='/manufacturers' element={<Manufacturers />} />}
      {hasAccessToRoute('/addresses', auth) && <Route path='/addresses' element={<Addresses />} />}
      {hasAccessToRoute('/users', auth) && <Route path='/users' element={<Users />} />}
      {hasAccessToRoute('/clients', auth) && <Route path='/clients' element={<Clients />} />}
      {<Route path='/' element={<HomePage isDashboardVisible={isDashboardVisible} auth={auth} />} />}
      {<Route path='*' element={<HomePage isDashboardVisible={isDashboardVisible} auth={auth} />} />}
    </Routes>
  )
}

export const Authentificated = () => {
  return <UserRoutes />
}
