import React from 'react'
import { Modal, Select } from 'antd';
import styled from 'styled-components';
import { device } from '../../style/breakpoints/device';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_ID_MAP } from '../../utils/Constants';
import i18n from '../../i18n';


const OrderItemOutOfStockModal = (props) => {
  const { visible, onCancel, title, items } = props
  const { t } = useTranslation()


  return (
    <ModalWrapper
      title={title}
      open={visible}
      destroyOnClose
      onCancel={onCancel}
      footer={null}
    >
      <p>{t('orders.outOfStock')}</p>
      <ul>
        {items.map(item => <li key={item.intItemID}>{item.name}</li>)}
      </ul>
    </ModalWrapper>
  )
}
const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    height: fit-content;
    p{
      margin-right: 20px;
      white-space: pre-line;
    }
    @media ${device.tablet} {
      height: initial;
    }
  }
`

export default OrderItemOutOfStockModal