import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Row, Col } from 'antd'
import styled from 'styled-components'
import { OrderItemTable } from '../elements/Table/OrderItemTable'
const { Title } = Typography

export const OrderPrint = forwardRef((props, ref) => {
  const { orderPrint, itemListPrint, priceList } = props
  const { t } = useTranslation()

  const Address = data => {
    return (
      <>
        {data.strName}
        <br />
        {data.strAddress1}
        <br />
        {data.strAddress2 ? <>{data.strAddress2}<br /></> : <></>}
        {data.strAddress3 ? <>{data.strAddress3}<br /></> : <></>}
        {data.strZIP} {data.strCity} <br />
        {data.strCountry}
      </>
    )
  }

  return (
    <div style={{ display: 'none' }}>
      <PrintPage ref={ref}>
        {orderPrint && itemListPrint && (
          <>
            <Row>
              <Col span={24}>
                <Title level={4}>
                  {t('orders.title')} {orderPrint && orderPrint?.strOrderTitle}
                </Title>
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={16}>
                {orderPrint && orderPrint?.orderStatusTextName && (<><strong>{t('orders.status')}:</strong>{orderPrint?.orderStatusTextName}<br /></>)}
                {orderPrint && orderPrint?.strCustomerOrderNo1 && (<><strong>{t('orders.strCustomerOrderNo')}:</strong>{orderPrint?.strCustomerOrderNo1}<br /></>)}
                {orderPrint && orderPrint?.strCostCenter && (<><strong>{t('orders.costCenter')}:</strong>{orderPrint?.strCostCenter}<br /></>)}
                <br />

                {orderPrint && orderPrint?.strContacPerson && (<><strong>{t('orders.contact')}:</strong>{orderPrint?.strContacPerson}<br /></>)}
                {orderPrint && orderPrint?.strContacTel && (<><strong>{t('orders.phone')}:</strong>{orderPrint?.strContacTel}<br /></>)}
                {orderPrint && orderPrint?.strContactMobile && (<><strong>{t('orders.mobile')}:</strong>{orderPrint?.strContactMobile}<br /></>)}
                {orderPrint && orderPrint?.strContactEMail && (<><strong>{t('orders.email')}:</strong>{orderPrint?.strContactEMail}<br /></>)}
                <br />
                {orderPrint && orderPrint.dtmExecutionDate && (<><strong>{t('orders.execution')}:</strong>{orderPrint.dtmExecutionDate.split(' ')[0]}<br /></>)}
                {orderPrint && orderPrint?.strDescription && (<><strong>{t('orders.description')}:</strong><br />{orderPrint?.strDescription}</>)}
                <br />
              </Col>

              <Col span={8}>
                <Row>
                  <Col span={24}>
                    {orderPrint.Address && orderPrint.Address[0] && (<AddressBox>
                      <strong>{t('orders.loadingAddress')}</strong>
                      <br />
                      {orderPrint.Address && orderPrint.Address[0] && Address(orderPrint.Address[0])}
                    </AddressBox>)}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={24}>
                    {orderPrint.Address && orderPrint.Address[1] && (<AddressBox>
                      <strong>{t('orders.deliveryAddress')}</strong>
                      <br />
                      {orderPrint.Address && orderPrint.Address[1] && Address(orderPrint.Address[1])}
                    </AddressBox>)}
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={24}>
                <PrintItemsTable>
                  <Typography.Paragraph style={{ alignSelf: 'end' }}>{priceList?.totalPrice ? `${t('orders.totalPrice')} : ${priceList?.totalPrice.toFixed(2)} CHF` : ''}</Typography.Paragraph>
                  {itemListPrint && <OrderItemTable pagination={false} dataSource={itemListPrint} priceList={priceList} />}
                </PrintItemsTable>
              </Col>
            </Row>
          </>
        )}
      </PrintPage>
    </div>
  )
})
const PrintPage = styled.div`
  padding: 10mm 10mm 10mm 10mm;
`

const AddressBox = styled.div`
  padding: 2.5mm 2.5mm 2.5mm 2.5mm;
  border: 1px solid #f0f0f0;
`

const PrintItemsTable = styled.div`
  .actions-column,
  .ant-table-column-sorter {
    display: none;
  }
  @media print {
    table {
      page-break-after: auto;
    }
    tr {
      page-break-inside: avoid;
      page-break-after: auto;
    }
    td {
      page-break-inside: avoid;
      page-break-after: auto;
    }
    thead {
      display: table-header-group;
    }
    tfoot {
      display: table-footer-group;
    }
  }
`
