import React, { useEffect, useState } from 'react'
import {
  Button,
  Upload,
} from 'antd';
import {
  UploadOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import styled from 'styled-components';

export const UploadSingleImage = (props) => {
  const { initialFile } = props
  const [image, setImage] = useState()
  const [imageLink, setImageLink] = useState()
  useEffect(() => {
    if (image) {
      setImageLink(URL.createObjectURL(image))
      if (props.onUpload) {
        props.onUpload(image)
      }
    } else {
      setImageLink()
      props.onUpload()
    }
  }, [image])

  useEffect(() => {
    if (initialFile) {
      fetch(initialFile).then(r => r.blob()).then(blob => {
        if (blob.type == 'text/html') {
          // Means error
          setImage()
        } else {
          var file = new File([blob], "logo");
          setImage(file)
        }
      }).catch(err => {
        setImage()
      });
    }
  }, [initialFile])

  return (
    <UploadWrapper>
      <Upload
        name="logo"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        accept="image/*"
        style={{ width: '100%' }}
        beforeUpload={(file) => {
          setImage(file)
          return false
        }}
      >
        {
          image ?
            <ImagePreview src={imageLink} onError={(error) => {
              setImage(new File([image], 'logo', { type: 'image/svg+xml' }))
            }} /> :
            <Button icon={<UploadOutlined />} />
        }
      </Upload>
      {
        image && <DeleteOutlined onClick={() => { setImage() }} />
      }
    </UploadWrapper>
  )
}

const ImagePreview = styled.img`
  width: 100%;
  height: auto;
`
const UploadWrapper = styled.div`
  height: fit-content;
  .ant-upload {
    width: 100%;
    height: fit-content;
  }
`
