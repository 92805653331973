import React, { createContext, useEffect, useState } from 'react'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { ACTIVITY_EXPIRATION } from '../utils/Constants'
import { postToAPI } from './API'
import { useCompany } from './CompanyProvider'

const AuthContext = createContext()

const AuthProvider = props => {
  const [authToken, setAuthToken] = useLocalStorage('authToken')
  const [activityExpiration, setActivityExpiration] = useLocalStorage('activityExpiration')
  const [token, setToken] = useState(authToken)
  const company = useCompany()

  const login = async (email, password) => {
    let result = false
    await postToAPI('auth/login', { email: email, password: password })
      .then(res => res.json())
      .then(data => {
        if (data.code !== 200) {
          result = 500
          return
        }
        if (!data.response.w4token) {
          result = 401
          return
        }
        if (data.response && data.response.Username) {
          const { w4token, expires_in, role, accessList } = data.response
          if (!w4token) {
            result = false
            return
          }
          const date = new Date()
          date.setSeconds(date.getSeconds() + expires_in)
          setAuthToken({
            expirationDate: date,
            email: email,
            w4token: w4token,
            role: role,
            accessList: accessList
          })
          // Set activity expiration date
          extendActivity()
          result = 200
        }
      })
      .catch(err => console.log(err))
    return result
  } // make a login request
  const logout = () => {
    setAuthToken({})
    window.history.replaceState(null, "", "/")
  } // clear the token in localStorage and the user data

  const extendActivity = () => {
    // Set activity expiration date
    const date = new Date()
    date.setSeconds(date.getSeconds() + ACTIVITY_EXPIRATION)
    setActivityExpiration(date);
  }

  useEffect(() => {
    setToken(authToken)
    company.refetch()
  }, [authToken])

  // note, I'm not bothering to optimize this `value` with React.useMemo here
  // because this is the top-most component rendered in our app and it will very
  // rarely re-render/cause a performance problem.
  return (
    <AuthContext.Provider
      value={{
        login, logout, token, setAuthToken, activityExpiration, extendActivity
      }}
      {...props}
    />
  )
}

const useAuth = () => React.useContext(AuthContext)

export { AuthProvider, useAuth }
