import i18n from '../i18n'
import { LANGUAGE_ID_MAP, LANGUAGE_DEFAULT } from './Constants'

const _getValueForLanguage = (field) => {
    const languageId = LANGUAGE_ID_MAP[i18n.language]
    const getValueForLanguage = (field, languageId) => {
        for(let i = 0; i < field.length; i++) {
            if(languageId === field[i].intLanguageID) {
                return field[i].strText
            }
        }
        return null
    }
    return getValueForLanguage(field, languageId)
        || getValueForLanguage(field, LANGUAGE_ID_MAP[ LANGUAGE_DEFAULT])
}

const sortByLanguage = (field, property) => {
    field.sort((a,b) => {
        return (_getValueForLanguage(a[property]) || '').localeCompare(
            _getValueForLanguage(b[property]) || ''
        )
    })
    return field
}

export { sortByLanguage }