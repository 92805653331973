import { Input } from 'antd'
import React from 'react'
import styled from 'styled-components'

const PhoneInput = (props) => {
  const isNumericInput = (event) => {
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };

  const isModifierKey = (event) => {
    const key = event.keyCode;
    return (key === 35 || key === 36) || // Allow Shift, Home, End
      (key === 8 || key === 9 || key === 13 || key === 46 || key === 32) || // Allow Backspace, Tab, Enter, Delete, Space
      (key === 109 || key === 189 || key === 173 || key === 187 || key === 107) || // Allow Plus, Minus
      (key > 36 && key < 41) || // Allow left, up, right, down
      (
        // Allow Ctrl/Command + A,C,V,X,Z
        (event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
      )
  };

  const enforceFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!isNumericInput(event) && !isModifierKey(event)) {
      event.preventDefault();
    }
  };

  return (
    <PhoneInputStyled {...props} type="text" onKeyDown={enforceFormat} />
  )
}

const PhoneInputStyled = styled(Input)`
  .ant-input-number-handler-wrap{
    display: none;
  }
  width: 100%;
`

export default PhoneInput