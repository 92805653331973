import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MainLayout } from '../../layouts/MainLayout'
import { Button, Space, Table, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { device } from '../../style/breakpoints/device'
import { getFromAPI } from '../../context/API'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { AddressModal } from '../../elements/Modal/AddressModal'
import { useAuth } from '../../context/AuthProvider'
import { hasAccessToRoute } from '../../utils/RoleUtils'

export const Addresses = () => {
  const { t } = useTranslation()
  const [addresses, setAddresses] = useState([])
  const [editAddress, setEditAddress] = useState()
  const [nameTimer, setNameTimer] = useState(null)
  const [cityTimer, setCityTimer] = useState(null)
  const [addressTimer, setAddressTimer] = useState(null)
  const [searchCompany, setSearchCompany] = useState()
  const [searchCity, setSearchCity] = useState()
  const [searchAddress, setSearchAddress] = useState()
  const [tableLoading, setTableLoading] = useState(true)
  const auth = useAuth()

  const handleNameChange = change => {
    if (!searchCompany && change.length < 3) {
      return
    }
    setTableLoading(true)
    if (nameTimer) {
      clearTimeout(nameTimer)
      setNameTimer(null)
    }
    setNameTimer(
      setTimeout(() => {
        setSearchCompany(change.length >= 3 ? change : '')
        setTableLoading(false)
      }, 750)
    )
  }

  const handleCityChange = change => {
    if (!searchCity && change.length < 3) {
      return
    }
    setTableLoading(true)
    if (cityTimer) {
      clearTimeout(cityTimer)
      setCityTimer(null)
    }
    setCityTimer(
      setTimeout(() => {
        setSearchCity(change.length >= 3 ? change : '')
        setTableLoading(false)
      }, 750)
    )
  }

  const handleAddressChange = change => {
    if (!searchAddress && change.length < 3) {
      return
    }
    setTableLoading(true)
    if (addressTimer) {
      clearTimeout(addressTimer)
      setAddressTimer(null)
    }
    setCityTimer(
      setTimeout(() => {
        setSearchAddress(change.length >= 3 ? change : '')
        setTableLoading(false)
      }, 750)
    )
  }

  useEffect(() => {
    updateAddresses(searchCompany, searchCity, searchAddress)
  }, [searchCompany, searchCity, searchAddress])

  const updateAddresses = (strCompany, strCity, strAddress) => {
    const query = {}

    if (strCompany) {
      query.strName = encodeURIComponent(strCompany)
    }
    if (strCity) {
      query.strCity = encodeURIComponent(strCity)
    }
    if (strAddress) {
      query.strAddress1 = encodeURIComponent(strAddress)
    }

    let url =
      'address/get?' +
      Object.keys(query)
        .map(k => {
          return `${k}=${query[k]}`
        })
        .join('&')

    getFromAPI(url, {}, auth)
      .then(res => res.json())
      .then(data => {
        if (data.response) {
          setAddresses(
            data.response.map((item, index) => {
              item.key = index
              return item
            })
          )
          setTableLoading(false)
        } else {
          setAddresses([])
          setTableLoading(false)
        }
      })
      .catch(() => {
        setAddresses([])
        setTableLoading(false)
      })
  }

  useEffect(() => {
    updateAddresses()
  }, [])

  const columns = [
    {
      title: t('addresses.company'),
      dataIndex: 'strName',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.strName.localeCompare(b.strName)
    },
    {
      title: t('addresses.address'),
      dataIndex: 'strAddress1',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.strAddress1.localeCompare(b.strAddress1),
      responsive: ['md']
    },
    {
      title: t('addresses.zip'),
      dataIndex: 'strZIP',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.strZIP.localeCompare(b.strZIP),
      responsive: ['md']
    },
    {
      title: t('addresses.city'),
      dataIndex: 'strCity',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.strCity.localeCompare(b.strCity)
    },
    ...hasAccessToRoute('/editAddress', auth) ? [{
      title: t('addresses.actions'),
      key: 'action',
      render: (_, record) => (
        <Space size='small'>
          <EditOutlined onClick={() => setEditAddress(record)} />
          {/* <DeleteOutlined /> */}
        </Space>
      )
    }] : [],

  ]

  return (
    <MainLayout>
      <div>
        <SearchBar layout='inline' colon={false}>
          <Form.Item name='strCompany' label={t('addresses.company')}>
            <Input
              onChange={e => handleNameChange(e.target.value)}
              type='text'
            />
          </Form.Item>
          <Form.Item name='strCity' label={t('addresses.city')}>
            <Input
              onChange={e => handleCityChange(e.target.value)}
              type='text'
            />
          </Form.Item>
          <Form.Item name='strAddress1' label={t('addresses.address')}>
            <Input
              onChange={e => handleAddressChange(e.target.value)}
              type='text'
            />
          </Form.Item>
        </SearchBar>
        <Table
          columns={columns}
          dataSource={addresses}
          showSorterTooltip={false}
          loading={tableLoading}
          scroll={{ x: "scroll"}}
        />
        {
          hasAccessToRoute('/editAddress', auth) &&
          <StyledSpace $emptyTable={addresses.length === 0}>
            <Button
              onClick={() => {
                setEditAddress(true)
              }}
            >
              {t('addresses.addAddress')}
            </Button>
          </StyledSpace>
        }

        <AddressModal
          visible={editAddress}
          afterClose={() => setEditAddress()}
          onSubmit={() => {
            setEditAddress()
            updateAddresses()
          }}
          values={editAddress}
          editMode={editAddress !== true}
          title={
            editAddress !== true
              ? t('addresses.editAddress')
              : t('addresses.addAddress')
          }
        />
      </div>
    </MainLayout>
  )
}

const SearchBar = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media ${device.tablet} {
    flex-direction: row;
  }
  width: 100%;
  margin-bottom: 10px;
`
const StyledSpace = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-top: ${props => (props.$emptyTable ? '10px' : '-50px')};
`
