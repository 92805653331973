import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MainLayout } from '../../layouts/MainLayout'
import { Button, Space, Table, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { device } from '../../style/breakpoints/device'
import { getFromAPI } from '../../context/API'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { ManufacturerModal } from '../../elements/Modal/ManufacturerModal'
import { useAuth } from '../../context/AuthProvider'
import { hasAccessToRoute } from '../../utils/RoleUtils'

export const Manufacturers = () => {
  const { t } = useTranslation()
  const [manufacturers, setManufacturers] = useState([])
  const [editManufacturer, setEditManufacturer] = useState()
  const [nameTimer, setNameTimer] = useState(null)
  const [searchCompany, setSearchCompany] = useState()
  const [tableLoading, setTableLoading] = useState(true)
  const [fetchController, setFetchController] = useState()
  const auth = useAuth()

  const handleNameChange = change => {
    if (!searchCompany && change.length < 3) {
      return
    }
    setTableLoading(true)
    if (nameTimer) {
      clearTimeout(nameTimer)
      setNameTimer(null)
    }
    setNameTimer(
      setTimeout(() => {
        setSearchCompany(change.length >= 3 ? change : '')
      }, 750)
    )
  }
  useEffect(() => {
    updateManufacturers(searchCompany)
  }, [searchCompany])

  const updateManufacturers = strCompany => {
    let url = 'item/getManufacturer?'
    if (strCompany) {
      url += 'strCompany=' + encodeURIComponent(strCompany)
    }
    if (tableLoading && fetchController) {
      fetchController.abort()
      setFetchController()
    }
    const controller = new AbortController();
    // signal to pass to fetch
    const signal = controller.signal;
    setFetchController(controller)
    getFromAPI(url, {}, auth, signal)
      .then(res => res.json())
      .then(data => {
        if (data.response) {
          setManufacturers(
            data.response.map((item, index) => {
              item.key = index
              return item
            })
          )
          setTableLoading(false)
        } else {
          setManufacturers([])
          setTableLoading(false)
        }
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setManufacturers([])
          setTableLoading(false)
        }

      })
  }

  useEffect(() => {
    updateManufacturers()
  }, [])

  const columns = [
    {
      title: t('manufacturers.company'),
      dataIndex: 'strCompany',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.strCompany.localeCompare(b.strCompany)
    },
    {
      title: t('manufacturers.address'),
      dataIndex: 'strAddress1',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.strAddress1.localeCompare(b.strAddress1),
      responsive: ['md']
    },
    {
      title: t('manufacturers.zip'),
      dataIndex: 'strZIP',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.strZIP.localeCompare(b.strZIP),
      responsive: ['md']
    },
    {
      title: t('manufacturers.city'),
      dataIndex: 'strCity',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.strCity.localeCompare(b.strCity)
    },
    ...hasAccessToRoute('/editManufacturer', auth) ? [{
      title: t('manufacturers.actions'),
      key: 'action',
      render: (_, record) => (
        <Space size='small'>
          <EditOutlined onClick={() => setEditManufacturer(record)} />
          {/* <DeleteOutlined /> */}
        </Space>
      )
    }] : [],

  ]

  return (
    <MainLayout>
      <div>
        <SearchBar layout='inline' colon={false}>
          <Form.Item name='strCompany' label={t('manufacturers.company')}>
            <Input
              onChange={e => handleNameChange(e.target.value)}
              type='text'
            />
          </Form.Item>
        </SearchBar>
        <Table
          columns={columns}
          dataSource={manufacturers}
          showSorterTooltip={false}
          loading={tableLoading}
          scroll={{ x: "scroll"}}
        />
        <StyledSpace $emptyTable={manufacturers.length === 0}>
          <Button
            onClick={() => {
              setEditManufacturer(true)
            }}
          >
            {t('manufacturers.addManufacturer')}
          </Button>
        </StyledSpace>
        <ManufacturerModal
          visible={editManufacturer}
          afterClose={() => setEditManufacturer()}
          onSubmit={() => {
            setEditManufacturer()
            updateManufacturers()
          }}
          values={editManufacturer}
          editMode={editManufacturer !== true}
          title={
            editManufacturer !== true
              ? t('manufacturers.editManufacturer')
              : t('manufacturers.addManufacturer')
          }
        />
      </div>
    </MainLayout>
  )
}

const SearchBar = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media ${device.tablet} {
    flex-direction: row;
  }
  width: 100%;
  margin-bottom: 10px;
`
const StyledSpace = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-top: ${props => (props.$emptyTable ? '10px' : '-50px')};
`
