import React from 'react'
import { Modal } from 'antd';
import styled from 'styled-components';
import { device } from '../../style/breakpoints/device';
import { CategoryForm } from '../Form/CategoryForm';

export const CategoryModal = (props) => {
  const { visible, afterClose, title, values, editMode, onSubmit } = props
  return (
    <ModalWrapper
      title={title}
      open={visible}
      onCancel={afterClose}
      destroyOnClose
      footer={null}
    >
      <CategoryForm editMode={editMode} values={values} onSubmit={onSubmit} />
    </ModalWrapper>
  )
}
const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    height: fit-content;
    @media ${device.tablet} {
      height: initial;
    }
  }
`
