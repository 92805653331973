export const LANGUAGE_ID_MAP = {
    'en': 1,
    'de': 2,
    'fr': 3,
    'it': 4,
}

export const SUPPORTEDLNGS = ['de', 'en', 'it', 'fr']

export const LANGUAGE_DEFAULT = 'de'

export const LANGUAGE_ID_DEFAULT = 2

export const ACTIVITY_EXPIRATION = 7200 // seconds
export const ACTIVITY_EXPIRATION_WARNING = 300 // seconds

export const COOKIE_OPTIONS = ['Essential','Analytics'];
