import React, { useEffect, useState } from 'react'
import { Form, Input, Button, notification } from 'antd';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { device } from '../../style/breakpoints/device';
import { LockOutlined } from '@ant-design/icons';
import logo from '../../assets/img/logo.svg'
import { useSearchParams, useNavigate } from 'react-router-dom';
import { postToAPI } from '../../context/API';
import { useAuth } from '../../context/AuthProvider';
import { openNotificationWithIcon } from '../../utils/Notification';

export const ResetPassword = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const auth = useAuth();
  const [resetEnabled, setResetEnabled] = useState(true)
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token')
  const email = searchParams.get('email')

  useEffect(() => {
    if (!token || !email) {
      navigate("/auth/forgot", { replace: true });
    }
  }, [token, email])

  const onFail = () => {
    setResetEnabled(true)
    openNotificationWithIcon('error', t('resetPassword.onReset.fail.title'), t('resetPassword.onReset.fail.description'))
  }

  const onFinish = (values) => {
    setResetEnabled(false)
    postToAPI('auth/resetPassword',
      {
        email: email,
        resetToken: token,
        password: values['password'],
        confirmPassword: values['confirmPassword']
      }).then(res => res.json()).then(data => {
        if (data.response.Success) {
          openNotificationWithIcon('success', t('resetPassword.onReset.success.title'), t('resetPassword.onReset.success.description'))
          setTimeout(() => {
            auth.login(email, values['password'])
            navigate('/', { replace: true })
          }, 3000);
        } else {
          onFail()
        }
      })
      .catch(err => { onFail() })
  };

  return (
    <PageContainer>
      <ResetPasswordForm
        name="normal_reset_password"
        className="login-form"
        onFinish={onFinish}
        size="large"
        validateTrigger='onSubmit'
      >
        <ImgWrapper>
          <StyledImg src={logo} />
        </ImgWrapper>
        <StyledMessage>{t('resetPassword.message')}</StyledMessage>
        <Form.Item
          name="password"
          rules={[{ required: true, message: '' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={t('resetPassword.passwordPlaceholder')}
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: '',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(''));
              },
            }),
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={t('resetPassword.confirmPasswordPlaceholder')}
          />
        </Form.Item>
        <Form.Item style={{ float: 'right' }}>
          <SubmitButton type="primary" htmlType="submit" className="login-form-button" disabled={!resetEnabled} >
            {t('resetPassword.resetButton')}
          </SubmitButton>
        </Form.Item>
      </ResetPasswordForm>
    </PageContainer>
  )
}

const StyledMessage = styled.p`
  @media ${device.tablet} {
    width: 350px;
    max-width: 350px;
  }
`

const SubmitButton = styled(Button)`
  background-color: ${props => props.theme.colors.primary};
  border-color: ${props => props.theme.colors.primary};
  align-self: center;
  &:active{
    background-color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
    color: #fff;
  }
  &:hover{
    background-color: #fff;
    border-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
  }
  &:focus{
    background-color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
    color: #fff;
    &:hover{
      background-color: #fff;
      border-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.primary};
    }
  }
`

const ResetPasswordForm = styled(Form)`
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 50px;
  height: 100%;
  width: 100%;
  @media ${device.tablet} {
    padding: 100px;
    height: fit-content;
    width: fit-content;
  }
`
const PageContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`
const StyledImg = styled.img`
  width: 100%;
  max-width: 300px;
  min-width: 150px;
`
