import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ResetPassword } from '../pages/Auth/ResetPassword'

// Pages
import { Login } from '../pages/Auth/Login'
import { ForgotPassword } from '../pages/Auth/ForgotPassword'

export const Rest = () => {
  return (
    <Routes>
      <Route path='/auth/login' element={<Login />} />
      <Route path='/auth/forgot' element={<ForgotPassword />} />
      <Route path='/auth/reset' element={<ResetPassword />} />
      <Route path='*' element={<Login />} />
    </Routes>
  )
}
