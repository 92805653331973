import React from 'react'
import ItemForm from '../../elements/Form/ItemForm'
import { MainLayout } from '../../layouts/MainLayout'

export const AddItem = () => {
  return (
    <MainLayout>
      <ItemForm />
    </MainLayout>
  )
}
