export const dateSorter = (dateStr1, dateStr2) => {
  const seperators = ['/', '.', '-']
  const seperator = seperators.find(sep => dateStr1.includes(sep))
  if (!seperator) {
    return null
  }
  dateStr1 = dateStr1.split(seperator).reverse().join('');
  dateStr2 = dateStr2.split(seperator).reverse().join('');
  return dateStr1.localeCompare(dateStr2);
}

export const alphaNumericSort = (arr = []) => {
  const sorter = (a, b) => {
    const isNumber = (v) => (+v).toString() === v;
    const aPart = a.value.match(/\d+|\D+/g);
    const bPart = b.value.match(/\d+|\D+/g);
    let i = 0; let len = Math.min(aPart.length, bPart.length);
    while (i < len && aPart[i] === bPart[i]) { i++; };
    if (i === len) {
      return aPart.length - bPart.length;
    };
    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
      return aPart[i] - bPart[i];
    };
    return aPart[i].localeCompare(bPart[i]);
  };
  return arr.sort(sorter);
}