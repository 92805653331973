// Works for background: #444aaa || #444
export const getContrastColor = (background) => {
  const black = "#000000"
  const white = "#FFFFFF"
  if(!background || background.length > 7 || (background.length < 7 && background.length !== 4)) return black
  if(background.length === 4 && background[0] === "#") {
    background = background.slice(0, 1) + background.slice(1, 4).repeat(2)
  }
  const r = parseInt(background.slice(1, 3), 16) / 255;
  const g = parseInt(background.slice(3, 5), 16) / 255;
  const b = parseInt(background.slice(5, 7), 16) / 255;

  // const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  // const contrastRatio = (luminance + 0.05) / 0.05;
  const contrastRatio = 0.2126 * r + 0.7152 * g + 0.0722 * b
  
  return contrastRatio >= 0.186 ? black : white;
}