export const BE_URL = process.env.REACT_APP_BE_URL
export const MEDIA_URL = process.env.REACT_APP_BE_URL + 'media/'

export const getFromAPI = (endpoint, headers, auth, signal) => {
  const seperator = endpoint.includes('?') ? '&' : '?'
  const endpointWithTimestamp = `${endpoint}${seperator}timestamp=${new Date().getTime()}`
  const token = auth?.token
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...headers }
  }
  if (signal) {
    requestOptions.signal = signal
  }
  if (token?.w4token) {
    auth.extendActivity()
    requestOptions.headers.w4token = token?.w4token
    return fetch(new URL(endpointWithTimestamp, BE_URL).href, requestOptions).then(
      async response => {
        const cloneResponse = response.clone()
        const resJson = await response.json()
        if (resJson?.isExpired) {
          auth.logout()
        }
        return cloneResponse
      }
    )
  } else {
    return fetch(new URL(endpoint, BE_URL).href, requestOptions)
  }
}

/**
 *
 * @param {*} endpoint
 * @param {*} parameters
 * @param {*} isForm
 * @param {*} auth
 * @returns
 */
export const postToAPI = (endpoint, parameters, isForm, auth) => {
  const seperator = endpoint.includes('?') ? '&' : '?'
  const endpointWithTimestamp = `${endpoint}${seperator}timestamp=${new Date().getTime()}`
  const headers = {}
  if (!isForm) {
    headers['Content-Type'] = 'application/json'
  }

  headers.w4token = auth?.token?.w4token
  const requestOptions = {
    method: 'POST',
    headers: headers,
    body: isForm ? parameters : JSON.stringify(parameters)
  }
  return fetch(new URL(endpointWithTimestamp, BE_URL).href, requestOptions).then(
    async response => {
      const cloneResponse = response.clone()
      const resJson = await response.json()
      if (resJson?.isExpired) {
        auth.logout()
      }
      return cloneResponse
    }
  )
}
