import React from 'react'

class ErrorBoundary extends React.Component {
  state = { hasError: false, error: undefined }
  static getDerivedStateFromError (error) {
    return { hasError: true, error: error }
  }
  componentDidCatch (error, errorInfo) {
    // errorService.log({ error, errorInfo });
  }
  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.{this.state.error}</h1>
    }
    return this.props.children
  }
}

export default ErrorBoundary
