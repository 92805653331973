import React, { useEffect, useState } from 'react'
import { Form, Input, InputNumber, Modal, Select } from 'antd';
import styled from 'styled-components';
import { device } from '../../style/breakpoints/device';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/lib/form/Form';
import { LANGUAGE_ID_DEFAULT, LANGUAGE_ID_MAP } from '../../utils/Constants';

import OrderItemTooManyModal from './OrderItemTooManyModal';
import i18n from '../../i18n';
const { Option } = Select;

const langID = LANGUAGE_ID_MAP[i18n.language]

const OrderItemQuantityModal = (props) => {
  const { visible, onCancel, onOk, title, values, okText, cancelText, orderTypes, reservation, roomNumbers } = props
  const { t } = useTranslation()
  const [form] = useForm()

  const [outOfOrder, setOutOfOrder] = useState(false)
  const [quantity, setQuantity] = useState(0)
  const [selectedType, setSelectedType] = useState(1)
  const [tooManyItemsSelected, setTooManyItemsSelected] = useState(false)

  useEffect(() => {
    if (values) {
      form.setFieldsValue(values)
      setSelectedType(values?.intOrderTyp || 1)
      setOutOfOrder(parseInt(visible.decQtyInStock) - parseInt(visible.decQtyReserved) <= 0)
    }
  }, [values])

  const getRoomNumber = (type) => {
    const numbers = Object.keys(roomNumbers || {}).filter(key => roomNumbers[key]).map(key => roomNumbers[key])
    if (numbers?.length === 1) {
      return numbers[0]
    }
    return ['2', '5'].includes(`${type}`) ? roomNumbers.default : roomNumbers[1]
  }

  const handleTypeSelect = (type) => {
    setSelectedType(type)
    if (roomNumbers) {
      form.setFieldsValue({ strRoom: getRoomNumber(type) })
    }
  }

  useEffect(() => {
    checkQuantity(quantity)
  }, [quantity])

  const available = visible && parseInt(visible.decQtyInStock) - parseInt(visible.decQtyReserved)
  let maxItems = visible && (values?.decQty ? available + values?.decQty : available)
  maxItems = maxItems < 0 ? 0 : maxItems

  const onSubmit = (formValues) => {
    const tooManyItem = checkQuantity(quantity)
    if (tooManyItem) {
      return
    }
    if (onOk) {
      const item = { ...values, decQty: parseInt(formValues.decQty), strRoom: formValues.strRoom || '', intOrderTyp: formValues.intOrderTyp }
      onOk(item)
    }
    setTooManyItemsSelected(false)
    form.resetFields()
  }

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
    setTooManyItemsSelected(false)
    form.resetFields()
  }

  const reduceItems = () => {
    const quantity = maxItems < 0 ? 0 : maxItems
    form.setFieldsValue({
      decQty: quantity,
    });
    setQuantity(quantity)
    setTooManyItemsSelected(false)
  }

  const continueWithCurrentAmount = () => {
    setTooManyItemsSelected(false)
  }

  const checkQuantity = (quantity) => {
    const tooManyItem = selectedType === 1 && quantity > maxItems
    tooManyItem && setTooManyItemsSelected(true)
    return tooManyItem
  }

  return (
    <ModalWrapper
      title={title}
      open={visible}
      destroyOnClose
      okText={okText}
      cancelText={cancelText}
      onOk={() => form.submit()}
      onCancel={handleCancel}
    >
      <p>{t('orders.amountDescription')}</p>
      <Form
        name='normal_order_item_quantity'
        onFinish={onSubmit}
        size='large'
        form={form}
        preserve={false}
        validateTrigger='onSubmit'
        labelAlign='left'
        labelWrap
        labelCol={{ flex: '100px' }}
        colon={false}
      >
        <p>
          {values && values.name}
        </p>
        <Form.Item
          name="intOrderTyp"
          rules={[{ required: true, message: '' }]}
          label={t('orders.orderType')}
          initialValue={1}
        >
          <Select
            showSearch
            allowClear
            placeholder={t('orders.selectPlaceholder')}
            optionFilterProp="children"
            style={{ width: '50%' }}
            popupMatchSelectWidth={false}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            onSelect={(type) => handleTypeSelect(type)}
          >
            {orderTypes && orderTypes.map((orderType, index) => {
              const text = orderType.strTypeName.find(name => name.intLanguageID === langID)
                || orderType.strTypeName.find(name => name.intLanguageID === LANGUAGE_ID_DEFAULT)
              return <Option key={index} value={orderType.intValue}>{text ? text.strText : `${orderType.intValue}`}</Option>
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name='decQty'
          initialValue={0}
          label={t('items.quantity')}
          rules={[
            {
              required: true,
              message: ''
            },
            ({ }) => ({
              validator(_, value) {
                if (value > 0) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(''));
              },
            }),
          ]}
        >
          <InputNumber onChange={setQuantity} type="number" min={0} style={{ width: '50%' }} />
        </Form.Item>
        <Form.Item
          name='strRoom'
          label={t('orders.room')}
        >
          <Input type="text" style={{ width: '50%' }} />
        </Form.Item>
      </Form>

      <OrderItemTooManyModal
        okText={t('orders.reduce')}
        cancelText={t('orders.continue')}
        visible={tooManyItemsSelected}
        maxItems={maxItems}
        onCancel={() => { reduceItems() }}
        onOk={() => { reduceItems() }}
      />
    </ModalWrapper>
  )
}
const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    height: fit-content;
    @media ${device.tablet} {
      height: initial;
    }
  }
`

export default OrderItemQuantityModal