
import { AppRoutes } from './routes';
import { AppProviders } from './context/AppProviders';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import { CookieConsentForm } from './elements/Form/CookieConsentForm';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProviders>
        <CookieConsentForm />
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </AppProviders>
    </QueryClientProvider>
  );
}

export default App;
