import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import i18backend from 'i18next-http-backend';

const supportedLngs = ['de', 'en', 'it', 'fr']
const fallbackLng = 'de'

// Check the system langugage if it is not initialized before
if (localStorage.getItem('wfLng') === null) {
  const locale = Intl.DateTimeFormat().resolvedOptions().locale
  const lang = locale.includes('-') ? locale.split('-')[0] : locale
  localStorage.setItem('wfLng', supportedLngs.includes(lang) ? lang : fallbackLng)
}


i18n
  .use(i18backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    backend:{
      requestOptions: {
        cache: 'no-store',
      }
    },
    fallbackLng: fallbackLng,
    detection: {
      lookupLocalStorage: 'wfLng', // change language key to re-initialize on all users
    },
    supportedLngs: supportedLngs,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18n;