import React, { createContext, useEffect, useContext, useState } from 'react'
import { getFromAPI } from './API'

const CompanyContext = createContext()

const CompanyProvider = props => {
  const [company, setCompany] = useState({})
  const appEnv = process.env.REACT_APP_ENV

  const saveCompany = async () => {
    getCompany().then(data => {
      setCompany(data)
    })
  }

  useEffect(() => {
    saveCompany()
  }
  , [])

  const getCompany = async () => {
    let url = `client/get?slugName=${getCompanySlug()}`
    const company = await getFromAPI(url)
      .then(res => res.json())
      .then(data => {
        return data.response
      })
      .catch(() => {
        return {}
      })
    return company
  }

  const getCompanySlug = () => {
    const isDev = appEnv === 'development'
    let companySlug
    if (isDev) {
      companySlug = 'test-company'
    } else {
      const subdomain = window.location.hostname.split('.')[0]
      companySlug = subdomain
    }
    return companySlug
  }

  return (
    <CompanyContext.Provider
      value={{ getCompanySlug, getCompany, data: company, refetch: saveCompany}}
      {...props}
    />
  )
}

const useCompany = () => useContext(CompanyContext)

export { CompanyProvider, useCompany }
