import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MainLayout } from '../../layouts/MainLayout'
import { Button, Space, Table, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { device } from '../../style/breakpoints/device'
import { getFromAPI } from '../../context/API'
import i18n from '../../i18n'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { CategoryModal } from '../../elements/Modal/CategoryModal'
import { LANGUAGE_ID_DEFAULT, LANGUAGE_ID_MAP } from '../../utils/Constants'
import { useAuth } from '../../context/AuthProvider'
import { hasAccessToRoute } from '../../utils/RoleUtils'

const MIN_SEARCH_DIGIT = 1

export const Categories = () => {
  const { t } = useTranslation()
  const [categories, setCategories] = useState([])
  const [editCategory, setEditCategory] = useState()
  const [numberTimer, setNumberTimer] = useState(null)
  const [nameTimer, setNameTimer] = useState(null)
  const [searchName, setSearchName] = useState()
  const [searchNumber, setSearchNumber] = useState()
  const [tableLoading, setTableLoading] = useState(true)
  const [fetchController, setFetchController] = useState()
  const auth = useAuth()

  const handleNameChange = change => {
    if (!searchName && change.length < MIN_SEARCH_DIGIT) {
      return
    }
    setTableLoading(true)
    if (nameTimer) {
      clearTimeout(nameTimer)
      setNameTimer(null)
    }
    setNameTimer(
      setTimeout(() => {
        setSearchName(change.length >= MIN_SEARCH_DIGIT ? change : '')
      }, 750)
    )
  }
  const handleNumberChange = change => {
    if (!searchNumber && change.length < MIN_SEARCH_DIGIT) {
      return
    }
    setTableLoading(true)
    if (numberTimer) {
      clearTimeout(numberTimer)
      setNumberTimer(null)
    }
    setNumberTimer(
      setTimeout(() => {
        setSearchNumber(change.length >= MIN_SEARCH_DIGIT ? change : '')
      }, 750)
    )
  }

  useEffect(() => {
    updateCategories(searchNumber, searchName)
  }, [searchNumber, searchName])

  const langID = LANGUAGE_ID_MAP[i18n.language]

  const updateCategories = (strNo, strSearch) => {
    let url = 'item/getCategory?'
    if (strNo) {
      url += 'strNo=' + encodeURIComponent(strNo) + '&'
    }
    if (strSearch) {
      url += 'strSearch=' + encodeURIComponent(strSearch)
    }
    if (tableLoading && fetchController) {
      fetchController.abort()
      setFetchController()
    }
    const controller = new AbortController();
    // signal to pass to fetch
    const signal = controller.signal;
    setFetchController(controller)
    getFromAPI(url, {}, auth, signal)
      .then(res => res.json())
      .then(data => {
        if (data.response) {
          setCategories(
            data.response.map((item, index) => {
              item.key = index
              const text = item.strName.find(
                name => name.intLanguageID === langID
              ) || item.strName.find(
                name => name.intLanguageID === LANGUAGE_ID_DEFAULT
              )
              if (text) {
                item.localizedName = text.strText
              }
              return item
            })
          )
          setTableLoading(false)
        } else {
          setCategories([])
          setTableLoading(false)
        }
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setCategories([])
          setTableLoading(false)
        }
      })
  }

  useEffect(() => {
    updateCategories()
  }, [])

  const columns = [
    {
      title: t('categories.categoryNumber'),
      dataIndex: 'strNo',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.strNo && a.strNo.localeCompare(b.strNo, undefined, { numeric: true, sensitivity: 'base' })
    },
    {
      title: t('categories.name'),
      dataIndex: 'localizedName',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.localizedName && a.localizedName.localeCompare(b.localizedName)
    },
    ...hasAccessToRoute('/editCategory', auth) ? [{
      title: t('categories.actions'),
      key: 'action',
      render: (_, record) => (
        <Space size='small'>
          <EditOutlined onClick={() => setEditCategory(record)} />
          {/* <DeleteOutlined /> */}
        </Space>
      )
    }] : [],
  ]

  return (
    <MainLayout>
      <div>
        <SearchBar layout='inline' colon={false}>
          <Form.Item name='number' label={t('groups.groupNumber')}>
            <Input
              onChange={e => handleNumberChange(e.target.value)}
              type='text'
            />
          </Form.Item>
          <Form.Item name='name' label={t('groups.name')}>
            <Input
              onChange={e => handleNameChange(e.target.value)}
              type='text'
            />
          </Form.Item>
        </SearchBar>
        <Table
          columns={columns}
          dataSource={categories}
          showSorterTooltip={false}
          loading={tableLoading}
          scroll={{ x: "scroll"}}
        />
        <StyledSpace $emptyTable={categories.length === 0}>
          <Button
            onClick={() => {
              setEditCategory(true)
            }}
          >
            {t('categories.addCategory')}
          </Button>
        </StyledSpace>
        <CategoryModal
          visible={editCategory}
          afterClose={() => setEditCategory()}
          onSubmit={() => {
            setEditCategory()
            updateCategories()
          }}
          values={editCategory}
          editMode={editCategory !== true}
          title={
            editCategory !== true
              ? t('categories.editCategory')
              : t('categories.addCategory')
          }
        />
      </div>
    </MainLayout>
  )
}

const SearchBar = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media ${device.tablet} {
    flex-direction: row;
  }
  width: 100%;
  margin-bottom: 10px;
`
const StyledSpace = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-top: ${props => (props.$emptyTable ? '10px' : '-50px')};
`
