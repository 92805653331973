import React, { useEffect, useState } from 'react'
import {
  Form,
  Input,
  Col,
  Row,
  Select,
  InputNumber,
  Checkbox,
  Upload,
  Modal,
  Button,
  Tabs,
  notification
} from 'antd'
import { useTranslation } from 'react-i18next'
import { useForm } from 'antd/lib/form/Form'
import i18n from '../../i18n'
import { PlusOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { device } from '../../style/breakpoints/device'
import { getFromAPI, postToAPI } from '../../context/API'
import { LANGUAGE_ID_DEFAULT, LANGUAGE_ID_MAP } from '../../utils/Constants'
import { openNotificationWithIcon } from '../../utils/Notification'
import { useAuth } from '../../context/AuthProvider'
import { sortByLanguage } from '../../utils/DataUtils'

const { Option } = Select
const { TabPane } = Tabs

const getBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => resolve(reader.result)

    reader.onerror = error => reject(error)
  })

function urltoFile(url, filename, mimeType) {
  return (fetch(url)
    .then(function (res) { return res.arrayBuffer(); })
    .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
  );
}

const ItemForm = props => {
  const { values, editMode, previewMode, onSubmit, onReady } = props
  const { t } = useTranslation()
  const [groups, setGroups] = useState([])
  const [categories, setCategories] = useState([])
  const [manufacturers, setManufacturers] = useState([])
  const [units, setUnits] = useState([])
  const [fileList, setFileList] = useState([])
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [imageDescription, setImageDescription] = useState('')
  const [loadingData, setLoadingData] = useState(false)
  const [intItemID, setIntItemID] = useState()
  const [updatingItem, setUpdatingItem] = useState(false);
  const auth = useAuth()
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8
        }}
      >
        {t('items.upload')}
      </div>
    </div>
  )

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }
  const beforeUpload = () => false

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreviewImage(file.url || file.preview)
    setPreviewVisible(file)
    setPreviewTitle(
      file.name || (file.url && file.url.substring(file.url.lastIndexOf('/') + 1))
    )
    setImageDescription(file.description)
  }

  const handleCancel = () => {
    setPreviewVisible(false)
    setImageDescription('')
  }

  const handleSaveDescription = () => {
    previewVisible.description = imageDescription
    setPreviewVisible(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      await updateGroups()
      const updatedManufacturers = await updateManufacturers()
      const updatedCategories = await updateCategories()
      const updatedUnits = await updateUnits()
      const formValues = values
      formValues.strName && formValues.strName.forEach(name => {
        const lang = Object.entries(LANGUAGE_ID_MAP).find(
          obj => obj[1] === name.intLanguageID
        )
        if (lang) {
          const langPrefix = lang[0]
          formValues[`${langPrefix}Name`] = name.strText
        }
      })
      formValues.strDescription && formValues.strDescription.forEach(desc => {
        const lang = Object.entries(LANGUAGE_ID_MAP).find(
          obj => obj[1] === desc.intLanguageID
        )
        if (lang) {
          const langPrefix = lang[0]
          formValues[`${langPrefix}Desc`] = desc.strText
        }
      })
      if (formValues.Photo) {
        const newFileList = []
        for (const photoFile of formValues.Photo) {
          const fileType = photoFile.strDataType && photoFile.strDataType.includes('image') ?
            photoFile.strDataType : `image/${photoFile.strDataType}`
          const url = `data:${fileType};charset=utf-8;base64,${photoFile.picItem}`
          const file = await urltoFile(url, photoFile.strFileName, fileType)

          const newFile = {}
          newFile.name = photoFile.strFileName
          newFile.description = photoFile.strDescription
          newFile.intItemPictureID = photoFile.intItemPictureID
          newFile.strDataType = fileType
          newFile.originFileObj = file
          newFileList.push(newFile)
        }
        setFileList(newFileList)
      }
      formValues['intItemID'] &&
        editMode &&
        setIntItemID(formValues['intItemID'])
      if (!(formValues['intItemCatID'] && updatedCategories && updatedCategories.find(cat => `${cat.intItemCatID}` === formValues['intItemCatID']))) {
        delete formValues['intItemCatID'];
      }
      if (!(formValues['intManufacturerID'] && updatedManufacturers && updatedManufacturers.find(man => `${man.intManufacturerID}` === formValues['intManufacturerID']))) {
        delete formValues['intManufacturerID'];
      }
      if (!(formValues['intUnit'] && updatedUnits && updatedUnits.find(unit => `${unit.intValue}` === formValues['intUnit']))) {
        delete formValues['intUnit'];
      }
      formValues['IsFIFOItem'] = formValues['IsFIFOItem'] === '1'
      formValues['IsLotItem'] = formValues['IsLotItem'] === '1'
      formValues['IsSerialNo'] = formValues['IsSerialNo'] === '1'
      form.setFieldsValue(formValues)
      setLoadingData(false)
      onReady(true)
    }
    if (values) {
      setLoadingData(true)
      fetchData()
    }
  }, [values])

  const langID = LANGUAGE_ID_MAP[i18n.language]

  const [form] = useForm()

  const onFinish = async values => {
    setUpdatingItem(true)
    if (intItemID && editMode) {
      values['intItemID'] = intItemID
    }
    const strNames = []
    values['enName'] &&
      strNames.push({ intLanguageID: 1, strText: values['enName'] })
    values['deName'] &&
      strNames.push({ intLanguageID: 2, strText: values['deName'] })
    values['frName'] &&
      strNames.push({ intLanguageID: 3, strText: values['frName'] })
    values['itName'] &&
      strNames.push({ intLanguageID: 4, strText: values['itName'] })
    delete values['enName']
    delete values['deName']
    delete values['frName']
    delete values['itName']
    values['strName'] = strNames
    const strDescriptions = []
    values['enDesc'] &&
      strDescriptions.push({ intLanguageID: 1, strText: values['enDesc'] })
    values['deDesc'] &&
      strDescriptions.push({ intLanguageID: 2, strText: values['deDesc'] })
    values['frDesc'] &&
      strDescriptions.push({ intLanguageID: 3, strText: values['frDesc'] })
    values['itDesc'] &&
      strDescriptions.push({ intLanguageID: 4, strText: values['itDesc'] })
    if (strDescriptions.length > 0) {
      values['strDescription'] = strDescriptions
    }
    delete values['enDesc']
    delete values['deDesc']
    delete values['frDesc']
    delete values['itDesc']
    const photos = []
    for (const file of fileList) {
      let picItem = await getBase64(file.originFileObj)
      // Remove base64 config
      if (picItem.includes(',')) {
        const index = picItem.indexOf(',')
        picItem = picItem.substring(index + 1)
      }
      const photoObj = {
        picItem: picItem,
        strDescription: file.description,
        strFilename: file.name,
        strDataType: file.type || file.strDataType
      }
      if (file.intItemPictureID) {
        photoObj.intItemPictureID = file.intItemPictureID
      }
      photos.push(photoObj)
    }
    if (photos.length > 0) {
      values['Photo'] = photos
    }

    values['IsFIFOItem'] = values['IsFIFOItem'] ? '1' : '0'
    values['IsLotItem'] = values['IsLotItem'] ? '1' : '0'
    values['IsSerialNo'] = values['IsSerialNo'] ? '1' : '0'
    const url = editMode ? 'item/edit' : 'item/create'
    postToAPI(url, values, false, auth)
      .then(res => res.json())
      .then(data => {
        setUpdatingItem(false)
        if (data.error) {
          openNotificationWithIcon(
            'error',
            t('groups.onFail.title'),
            <>
              <>{t('groups.onFail.description')}</>
              <br />
              <div
                dangerouslySetInnerHTML={{
                  __html: data.response.Message || data.response
                }}
              />
            </>
          )
        } else {
          openNotificationWithIcon(
            'success',
            t('items.onSuccess.title'),
            t('items.onSuccess.description')
          )
          if (!editMode) {
            form.resetFields()
            setFileList([])
          }
        }
        if (onSubmit) {
          onSubmit(data)
        }
      })
      .catch(err => {
        setUpdatingItem(false)
        openNotificationWithIcon(
          'error',
          t('groups.onFail.title'),
          t('groups.onFail.description')
        )
      })
  }

  const updateGroups = async () => {
    return await getFromAPI('item/getGroup', {}, auth)
      .then(res => res.json())
      .then(data => {
        if (data.response) {
          const updatedGroups = data.response.map((item, index) => {
            item.key = index
            return item
          })
          setGroups(sortByLanguage(updatedGroups, 'strName'))
          return updatedGroups
        }
      })
  }

  const updateCategories = async () => {
    return await getFromAPI('item/getCategory', {}, auth)
      .then(res => res.json())
      .then(data => {
        if (data.response) {
          const updatedCategories = data.response.map((item, index) => {
            item.key = index
            return item
          })
          setCategories(sortByLanguage(updatedCategories, 'strName'))
          // Set default category to MIDI if creating new item and midi category exists
          if(!editMode) {
            const defaultCategory = updatedCategories.find(cat => cat.strName.some(name => name.strText?.toLowerCase() === 'midi'))?.intItemCatID;
            if (defaultCategory) {
              form.setFieldsValue({ intItemCatID: defaultCategory?.toString()  })
            }
          }
          return updatedCategories
        }
      })
  }

  const updateUnits = async () => {
    return await getFromAPI('type/getType?intParentID=1072', {}, auth)
      .then(res => res.json())
      .then(data => {
        if (data.response) {
          const updatedUnits = data.response.map((item, index) => {
            item.key = index
            return item
          })
          setUnits(sortByLanguage(updatedUnits, 'strTypeName'))
          return updatedUnits
        }
      })
  }

  const updateManufacturers = async () => {
    return await getFromAPI('item/getManufacturer', {}, auth)
      .then(res => res.json())
      .then(data => {
        if (data.response) {
          const updatedManufacturers = data.response.map((item, index) => {
            item.key = index
            return item
          })
          setManufacturers(sortByLanguage(updatedManufacturers, 'strCompany'))
          return updatedManufacturers
        }
      })
  }

  useEffect(() => {
    updateGroups()
    updateManufacturers()
    updateCategories()
    updateUnits()
  }, [])

  const tabItems = [
    {
      key: '1',
      label: 'de',
      children: (
        <>
          <Form.Item name='deName' labelCol={{ flex: '120px' }} label={'Name'}>
            <Input type='text' />
          </Form.Item>
          <Form.Item
            name='deDesc'
            labelCol={{ flex: '120px' }}
            label={'Description'}
            style={{ marginBottom: 0 }}
          >
            <Input.TextArea style={{ resize: 'none' }} />
          </Form.Item>
        </>
      ),
    },
    {
      key: '2',
      label: 'en',
      children: (
        <>
          <Form.Item name='enName' labelCol={{ flex: '120px' }} label={'Name'}>
            <Input type='text' />
          </Form.Item>
          <Form.Item
            name='enDesc'
            labelCol={{ flex: '120px' }}
            label={'Description'}
            style={{ marginBottom: 0 }}
          >
            <Input.TextArea style={{ resize: 'none' }} />
          </Form.Item>
        </>
      ),
    },
    {
      key: '3',
      label: 'fr',
      children: (
        <>
          <Form.Item name='frName' labelCol={{ flex: '120px' }} label={'Name'}>
            <Input type='text' />
          </Form.Item>
          <Form.Item
            name='frDesc'
            labelCol={{ flex: '120px' }}
            label={'Description'}
            style={{ marginBottom: 0 }}
          >
            <Input.TextArea style={{ resize: 'none' }} />
          </Form.Item>
        </>
      ),
    },
    {
      key: '4',
      label: 'it',
      children: (
        <>
          <Form.Item name='itName' labelCol={{ flex: '120px' }} label={'Name'}>
            <Input type='text' />
          </Form.Item>
          <Form.Item
            name='itDesc'
            labelCol={{ flex: '120px' }}
            label={'Description'}
            style={{ marginBottom: 0 }}
          >
            <Input.TextArea style={{ resize: 'none' }} />
          </Form.Item>
        </>
      ),
    },
  ]

  return (
    <FormWrapper disabled={previewMode || loadingData}>
      <Form
        name='normal_edit_item'
        onFinish={onFinish}
        size='large'
        form={form}
        preserve={false}
        validateTrigger='onSubmit'
        labelCol={{ flex: '130px' }}
        labelAlign='right'
        colon={false}
        labelWrap
      >
        <Row>
          <Col span={24} lg={{ span: 10, offset: 1 }}>
            <Form.Item
              name='intItemGrpID'
              rules={[{ required: true, message: '' }]}
              label={t('items.group')}
            >
              <Select
                showSearch
                allowClear
                placeholder={t('items.selectPlaceholder')}
                optionFilterProp='children'
                style={{ minWidth: 150 }}
                popupMatchSelectWidth={false}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {groups.map((group, index) => {
                  const text = group.strName.find(
                    name => name.intLanguageID === langID
                  ) || group.strName.find(
                    name => name.intLanguageID === LANGUAGE_ID_DEFAULT
                  )
                  return (
                    <Option key={index} value={`${group.intItemGrpID}`}>
                      {text ? text.strText : `${group.intItemGrpID}`}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item name='intItemCatID' label={t('items.category')}>
              <Select
                showSearch
                allowClear
                placeholder={t('items.selectPlaceholder')}
                optionFilterProp='children'
                style={{ minWidth: 150 }}
                popupMatchSelectWidth={false}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {categories.map((category, index) => {
                  const text = category.strName.find(
                    name => name.intLanguageID === langID
                  ) || category.strName.find(
                    name => name.intLanguageID === LANGUAGE_ID_DEFAULT
                  )
                  return (
                    <Option key={index} value={`${category.intItemCatID}`}>
                      {text ? text.strText : `${category.intItemCatID}`}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name='strItemNo'
              rules={[{ required: true, message: '' }]}
              label={t('items.itemNumber')}
            >
              <Input type='text' />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 0 }}
              rules={[
                {
                  message: t('items.nameWarning'),
                  required: true,
                  validator: (_, value) => {
                    const values = form.getFieldsValue()
                    const enName = values['enName']
                    const deName = values['deName']
                    const frName = values['frName']
                    const itName = values['itName']
                    if (enName || deName || frName || itName) {
                      return Promise.resolve()
                    } else {
                      return Promise.reject()
                    }
                  }
                }
              ]}
            >
              <CardContainer>
                <Tabs
                  defaultActiveKey='1'
                  type='card'
                  size='small'
                  style={{ pointerEvents: 'all' }}
                  items={tabItems}
                />
                  
              </CardContainer>
            </Form.Item>
            <div style={{ pointerEvents: 'all' }}>
              <Upload
                listType='picture-card'
                fileList={fileList}
                onPreview={handlePreview}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                disabled={previewMode}
                accept={'image/*'}
              >
                {!previewMode && uploadButton}
              </Upload>
            </div>
            <Modal
              open={previewVisible}
              title={previewTitle}
              onCancel={handleCancel}
              onOk={handleSaveDescription}
              destroyOnClose
              okText={t('modals.ok')}
              cancelText={t('modals.cancel')}
            >
              <img
                alt='example'
                style={{
                  width: '100%'
                }}
                src={previewImage}
              />
              {!previewMode && (
                <Form.Item
                  label={t('items.description')}
                  style={{ marginTop: 24 }}
                >
                  <Input.TextArea
                    style={{ resize: 'none' }}
                    value={imageDescription}
                    onChange={e => setImageDescription(e.target.value)}
                  />
                </Form.Item>
              )}
            </Modal>
          </Col>

          <Col span={24} lg={{ span: 10, offset: 1 }}>
            <Form.Item name='intManufacturerID' label={t('items.manufacturer')}>
              <Select
                showSearch
                allowClear
                placeholder={t('items.selectPlaceholder')}
                optionFilterProp='children'
                style={{ minWidth: 150 }}
                popupMatchSelectWidth={false}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {manufacturers.map((manufacturer, index) => {
                  return (
                    <Option key={index} value={`${manufacturer.intManufacturerID}`}>
                      {manufacturer.strCompany}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item name='decVolume' label={t('items.volume')}>
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label={t('items.dimensions')}>
              <Row style={{ height: 40 }}>
                <Col span={6}>
                  <Form.Item name='decWidth'>
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={6} offset={3}>
                  <Form.Item name='decLength'>
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={6} offset={3}>
                  <Form.Item name='decHeight'>
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item name='strColor' label={t('items.color')}>
              <Input type='text' />
            </Form.Item>
            <Form.Item name='strMaterial' label={t('items.material')}>
              <Input type='text' />
            </Form.Item>
            <Form.Item name='intUnit' label={t('items.unit')}>
              <Select
                showSearch
                allowClear
                placeholder={t('items.selectPlaceholder')}
                optionFilterProp='children'
                style={{ minWidth: 150 }}
                popupMatchSelectWidth={false}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {units.map((unit, index) => {
                  const text = unit.strTypeName.find(
                    name => name.intLanguageID === langID
                  ) || unit.strTypeName.find(
                    name => name.intLanguageID === LANGUAGE_ID_DEFAULT
                  )
                  return (
                    <Option key={index} value={`${unit.intValue}`}>
                      {text ? text.strText : `${unit.intValue}`}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item name='strOwner' label={t('items.owner')}>
              <Input type='text' />
            </Form.Item>
            <Form.Item label={t('items.properties.title')}>
              <Form.Item name='IsFIFOItem' valuePropName='checked'>
                <Checkbox style={{ lineHeight: '32px' }}>
                  {t('items.properties.fifo')}
                </Checkbox>
              </Form.Item>
              <Form.Item name='IsLotItem' valuePropName='checked'>
                <Checkbox style={{ lineHeight: '32px' }}>
                  {t('items.properties.batch')}
                </Checkbox>
              </Form.Item>
              <Form.Item
                name='IsSerialNo'
                valuePropName='checked'
                style={{ marginBottom: 0 }}
              >
                <Checkbox style={{ lineHeight: '32px' }}>
                  {t('items.properties.serialNo')}
                </Checkbox>
              </Form.Item>
            </Form.Item>
            <Form.Item name='decQtyInBox' label={t('items.quantityPerBox')}>
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label={t('items.quantityMinMax')}>
              <Row style={{ height: 40 }}>
                <Col span={11}>
                  <Form.Item name='decQtyMin'>
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={11} offset={2}>
                  <Form.Item name='decQtyMax'>
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        {!previewMode && (
          <Row justify='center'>
            <Col span={24} lg={20}>
              <Row justify='end'>
                <Form.Item>
                  <Button htmlType='submit' disabled={updatingItem}>
                    {editMode ? t('items.confirmChanges') : t('items.createItem')}
                  </Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
        )}
      </Form>
    </FormWrapper>
  )
}
const FormWrapper = styled.div`
  .ant-modal-body {
    height: fit-content;
    @media ${device.tablet} {
      height: initial;
    }
  }
  pointer-events: ${props => props.disabled && 'none'};
  opacity: ${props => props.disabled && '0.7'};
  .ant-tabs-nav-wrap {
    @media ${device.tablet} {
      margin-left: 120px;
    }
  }
`
const CardContainer = styled.div`
   .card-container p {
    margin: 0;
  }
  .ant-tabs-card .ant-tabs-content {
    margin-top: -16px;
    margin-bottom: 16px;
    border-top: solid 1px rgb(217, 217, 217);
    border-bottom: solid 1px rgb(217, 217, 217);
  }
  .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 16px 0 16px 0;
  }
`

export default ItemForm
