import * as ExcelJS from "exceljs"
import { getFromAPI } from "../context/API"
import i18n from "../i18n"
import { LANGUAGE_ID_DEFAULT, LANGUAGE_ID_MAP } from "./Constants"

const langID = LANGUAGE_ID_MAP[i18n.language]

const updateFields = async (auth, endpoint) => {
  return await getFromAPI(endpoint, {}, auth)
    .then(res => res.json())
    .then(data => {
      if (data.response) {
        const updatedFields = data.response.map((item, index) => {
          item.key = index
          return item
        })
        return updatedFields
      }
    })
}

const getHeightAndWidthFromDataUrl = dataURL => new Promise(resolve => {
  const img = new Image()
  img.onload = () => {
    resolve({
      height: img.height,
      width: img.width
    })
  }
  img.src = dataURL
})


const processItem = async (item, updatedGroups, updatedManufacturers, updatedCategories, updatedUnits) => {
  const formValues = item
  formValues.strName && formValues.strName.forEach(name => {
    const lang = Object.entries(LANGUAGE_ID_MAP).find(
      obj => obj[1] === name.intLanguageID
    )
    if (lang) {
      const langPrefix = lang[0]
      formValues[`${langPrefix}Name`] = name.strText
    }
  })
  formValues.strDescription && formValues.strDescription.forEach(desc => {
    const lang = Object.entries(LANGUAGE_ID_MAP).find(
      obj => obj[1] === desc.intLanguageID
    )
    if (lang) {
      const langPrefix = lang[0]
      formValues[`${langPrefix}Desc`] = desc.strText
    }
  })
  const photos = []
  if (formValues.Photo) {
    for (const photoFile of formValues.Photo) {
      const fileType = photoFile.strDataType && photoFile.strDataType.includes('image') ?
        photoFile.strDataType.split('/')[1] : photoFile.strDataType
      const url = `data:${fileType};charset=utf-8;base64,${photoFile.picItem}`
      photos.push({ base64: url, extension: fileType })
    }
  }
  delete formValues['Photo'];
  formValues.photos = photos
  if (formValues['intItemGrpID'] && updatedGroups) {
    const group = updatedGroups.find(grp => `${grp.intItemGrpID}` === formValues['intItemGrpID'])
    formValues['group'] = '';
    if (group) {
      const text = group.strName.find(
        name => name.intLanguageID === langID
      ) || group.strName.find(
        name => name.intLanguageID === LANGUAGE_ID_DEFAULT
      )
      formValues['group'] = text && text.strText;
    }
    delete formValues['intItemGrpID'];
  }
  if (formValues['intItemCatID'] && updatedCategories) {
    const category = updatedCategories.find(cat => `${cat.intItemCatID}` === formValues['intItemCatID'])
    formValues['category'] = '';
    if (category) {
      const text = category.strName.find(
        name => name.intLanguageID === langID
      ) || category.strName.find(
        name => name.intLanguageID === LANGUAGE_ID_DEFAULT
      )
      formValues['category'] = text && text.strText;
    }
    delete formValues['intItemCatID'];
  }
  if (formValues['intManufacturerID'] && updatedManufacturers) {
    const manufacturer = updatedManufacturers.find(man => `${man.intManufacturerID}` === formValues['intManufacturerID'])
    formValues['manufacturer'] = manufacturer && manufacturer.strCompany;
    delete formValues['intManufacturerID'];
  }
  if (formValues['intUnit'] && updatedUnits) {
    const unit = updatedUnits.find(unit => `${unit.intValue}` === formValues['intUnit'])
    formValues['unit'] = '';
    if (unit) {
      const text = unit.strTypeName.find(
        name => name.intLanguageID === langID
      ) || unit.strTypeName.find(
        name => name.intLanguageID === LANGUAGE_ID_DEFAULT
      )
      formValues['unit'] = text && text.strText;
    }
    delete formValues['intUnit'];
  }
  formValues['IsFIFOItem'] = formValues['IsFIFOItem'] === '1'
  formValues['IsLotItem'] = formValues['IsLotItem'] === '1'
  formValues['IsSerialNo'] = formValues['IsSerialNo'] === '1'
  delete formValues['strDescription'];
  delete formValues['strName'];
  return formValues
}

const prepareItems = async (auth, url) => {
  const items = await getFromAPI(url, {}, auth)
    .then(res => res.json())
    .then(data => {
      return data.response
    })
    .catch(err => {
      console.log(err)
    })
  if (!items) {
    return
  }
  const promises = []
  const updatedGroups = await updateFields(auth, 'item/getGroup')
  const updatedManufacturers = await updateFields(auth, 'item/getManufacturer')
  const updatedCategories = await updateFields(auth, 'item/getCategory')
  const updatedUnits = await updateFields(auth, 'type/getType?intParentID=1072')
  const processedItems = []
  items.forEach(item => {
    const promise = processItem(item, updatedGroups, updatedManufacturers, updatedCategories, updatedUnits)
      .then(item => processedItems.push(item))
    promises.push(promise)
  });
  await Promise.all(promises)
  return processedItems
}

export const exportToSheet = async (t, auth, url) => {
  const workbook = new ExcelJS.Workbook();
  workbook.views = [
    {
      x: 0, y: 0, width: 10000, height: 20000,
      firstSheet: 0, activeTab: 1, visibility: 'visible'
    }
  ]
  const sheet = workbook.addWorksheet('Articles');
  sheet.columns = [
    { header: t('items.itemNumber'), key: 'strItemNo', width: 15 },
    { header: t('items.group'), key: 'group', width: 15 },
    { header: t('items.category'), key: 'category', width: 15 },
    { header: t('items.manufacturer'), key: 'manufacturer', width: 15 },
    { header: t('items.name') + ' DE', key: 'deName', width: 15 },
    { header: t('items.name') + ' EN', key: 'enName', width: 15 },
    { header: t('items.name') + ' FR', key: 'frName', width: 15 },
    { header: t('items.name') + ' IT', key: 'itName', width: 15 },
    { header: t('items.description') + ' DE', key: 'deDesc', width: 20 },
    { header: t('items.description') + ' EN', key: 'enDesc', width: 20 },
    { header: t('items.description') + ' FR', key: 'frDesc', width: 20 },
    { header: t('items.description') + ' IT', key: 'itDesc', width: 20 },
    { header: t('items.available'), key: 'decQtyAvailable' },
    { header: t('items.inStock'), key: 'decQtyInStock' },
    { header: t('items.reserved'), key: 'decQtyReserved' },
    { header: t('items.volume'), key: 'decVolume' },
    { header: 'W', key: 'decWidth' },
    { header: 'L', key: 'decLength' },
    { header: 'H', key: 'decHeight' },
    { header: t('items.color'), key: 'strColor' },
    { header: t('items.material'), key: 'strMaterial' },
    { header: t('items.unit'), key: 'unit' },
    { header: t('items.owner'), key: 'strOwner' },
    { header: t('items.properties.fifo'), key: 'IsFIFOItem' },
    { header: t('items.properties.batch'), key: 'IsLotItem' },
    { header: t('items.properties.serialNo'), key: 'IsSerialNo' },
    { header: t('items.quantityPerBox'), key: 'decQtyInBox' },
    { header: t('items.quantity') + ' Min', key: 'decQtyMin' },
    { header: t('items.quantity') + ' Max', key: 'decQtyMax' },
    { header: 'Photo1', key: 'photo1' },
  ];
  const photoExportDisabled = true // According to request of customer WF-175
  const items = await prepareItems(auth, url)
  for (let index = 0; index < items.length; index++) {
    const item = items[index];
    sheet.addRow(item)
    if (item.photos && !photoExportDisabled) {
      for (let pIndex = 0; pIndex < item.photos.length; pIndex++) {
        const photo = item.photos[pIndex];
        const imageId = workbook.addImage(photo);
        const dimensions = await getHeightAndWidthFromDataUrl(photo.base64)
        const rowIndex = index + 1
        const colIndex = 28 + pIndex
        const col = sheet.getColumn(colIndex + 1);
        const row = sheet.getRow(rowIndex + 1);
        if (pIndex > 0) {
          col.key = `photo${pIndex + 1}`;
          col.header = `Photo${pIndex + 1}`;
        }
        col.width = col.width || 1
        row.height = row.height || 1
        col.width = dimensions.width / 8 > col.width ? dimensions.width / 8 : col.width
        row.height = dimensions.height * 0.8 > row.height ? dimensions.height * 0.8 : row.height
        sheet.addImage(imageId, {
          tl: { col: colIndex, row: rowIndex },
          ext: { width: dimensions.width, height: dimensions.height }
        });
      }
    }
  }
  workbook.xlsx.writeBuffer().then(buffer => {
    const data = new Blob([buffer])
    const sheetUrl = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.href = sheetUrl;
    tempLink.setAttribute('download', '_articles.xlsx');
    tempLink.click();
  })
    .catch(err => console.log('Error writing excel export', err))
}