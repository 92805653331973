import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Layout, Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  QuestionOutlined,
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
  CloseOutlined,
  MenuOutlined
} from '@ant-design/icons';

import { useAuth } from '../context/AuthProvider';
import { device } from '../style/breakpoints/device'

import footerLogo from '../assets/img/logo.svg'
import clientLogo from '../assets/img/credit-suisse.svg'
import wfLogo from '../assets/img/welti_furrer_logo.svg'
import ResetPasswordModal from '../elements/Modal/ResetPasswordModal';
import { MEDIA_URL, postToAPI } from '../context/API';
import { SideMenu } from './SideMenu';
import { openNotificationWithIcon } from '../utils/Notification';
import { useCompany } from '../context/CompanyProvider';
import AntProvider from '../context/AntProvider';
import { useCookieConsent } from '../context/CookieConsentProvider';

const { Header, Footer, Content } = Layout;

export const MainLayout = (props) => {
  const { children, withoutShadow } = props
  const { t } = useTranslation();
  const auth = useAuth()
  const navigate = useNavigate();
  const company = useCompany();
  const [logoUrl, setLogoUrl] = useState('')
  const [logoError, setLogoError] = useState(false)
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  // Set default collapsed on mobile to prevent flickering
  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const [menuCollapsed, setMenuCollapsed] = useState(screenWidth < 1024)
  const onChangePasswordFail = (err) => {
    setChangePasswordLoading(false)
    openNotificationWithIcon('error', t('changePassword.onReset.fail.title'), t('changePassword.onReset.fail.description'))
  }
    const { openConsentBanner } = useCookieConsent();

  useEffect(() => {
    if (company) {
      const url = MEDIA_URL + company.getCompanySlug() + '/logo'
      if (!logoUrl) {
        fetch(url).then(r => r.blob()).then(async blob => {
          const file = new File([blob], 'logo', { type: 'image/svg+xml' })
          // If file not found, set as error
          setLogoError(file.size < 200)
          const img = new Image()
          img.src = URL.createObjectURL(file)
          img.decode().then(() => {
            setLogoUrl(img.src)
          }).catch(() => {
            setLogoUrl(url)
          })
        });
      }
    }
  }, [company])

  const onChangePassword = (values) => {
    const { token, email } = auth.token
    setChangePasswordLoading(true)
    postToAPI('auth/changePassword',
      {
        email: email,
        resetToken: token,
        password: values['password'],
        newPassword: values['newPassword'],
        confirmPassword: values['confirmPassword']
      }, false, auth).then(res => res.json()).then(data => {
        if (data.response.access_token) {
          const { access_token, expires_in } = data.response
          const date = new Date()
          date.setSeconds(date.getSeconds() + expires_in)
          auth.setAuthToken({ token: access_token, expirationDate: date, email: email })
        }
        if (data.response.Success) {
          openNotificationWithIcon('success', t('changePassword.onReset.success.title'), t('changePassword.onReset.success.description'))
          auth.login(email, values['newPassword'])
          setChangePasswordLoading(false)
          setChangePasswordVisible(false)
        } else {
          onChangePasswordFail()
        }
      })
      .catch(err => { onChangePasswordFail(err) })
  }



  const ProfileMenu = {
    items: [
      {
        label: t('header.preferences'),
        key: '1',
        icon: <SettingOutlined />,
        onClick: () => {
          setChangePasswordVisible(true);
        },
      },
      {
        label: t('header.logout'),
        key: '2',
        icon: <LogoutOutlined />,
        onClick: () => {
          auth.logout();
          navigate('/', { replace: true });
        },
      },
    ],
  };



  return (
    <PageContainer>
      <AntProvider>
        <ResetPasswordModal
          visible={changePasswordVisible}
          onOk={onChangePassword}
          onCancel={() => { setChangePasswordVisible(false) }}
          confirmLoading={changePasswordLoading}
        />
        <StyledLayout>
          <StyledHeader>
            <HamburgerButton>
              {
                menuCollapsed ?
                  <MenuOutlined onClick={() => setMenuCollapsed(!menuCollapsed)} /> :
                  <CloseOutlined onClick={() => setMenuCollapsed(!menuCollapsed)} />
              }
            </HamburgerButton>
            <StyledImg src={logoUrl} $error={logoError} />
            <HeaderIcons>
              <Dropdown trigger={['click']} menu={ProfileMenu}>
                <IconWrapper>
                  <UserOutlined style={{ fontSize: '180%', backgroundColor: 'transparent' }} />
                </IconWrapper>
              </Dropdown>
            </HeaderIcons>
          </StyledHeader>
          <StyledLayout>
            <SideMenu onCollapse={setMenuCollapsed} menuCollapsed={menuCollapsed} />
            <StyledContent>{!withoutShadow ? <Container>{children}</Container> : children}</StyledContent>
          </StyledLayout>
          <StyledFooter>
            <FooterLogo src={footerLogo} />
            <CookiePreference onClick={openConsentBanner}>{t('cookieConsent.cookiePreference')}</CookiePreference>
          </StyledFooter>
        </StyledLayout>
      </AntProvider>
    </PageContainer>
  )
}

const HeaderIcons = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  gap: 5px;
`
const IconWrapper = styled.div`
  border: 3px solid;
  border-radius: 50%;
  display: flex;
  overflow: hidden;
  cursor: pointer; 
`
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  .ant-menu-inline{
    background-color: transparent !important;
  }
  .ant-menu-item-selected {
    background-color: transparent !important;
    color: ${props => props.theme.colors.primary} !important;
  }
  .ant-menu-item:hover {
    color: ${props => props.theme.colors.primary} !important;
  }
  .ant-menu-submenu-selected {
    color: ${props => props.theme.colors.primary} !important;
  }
  .ant-menu-submenu-title:hover {
    color: ${props => props.theme.colors.primary} !important;
  }
  .ant-menu-item::after {
    border-color: ${props => props.theme.colors.primary} !important;
  }
  .ant-menu-item-selected {
    border-radius: 0px !important;
    border-right: 3px solid !important;
    border-color: ${props => props.theme.colors.primary} !important;
  }
  .ant-menu-submenu-arrow{
    color: ${props => props.theme.colors.primary};
  }
  .ant-menu-submenu-arrow::after{
    color: ${props => props.theme.colors.primary};
  }
  .ant-menu-submenu-arrow::before{
    color: ${props => props.theme.colors.primary};
  }
  .ant-layout-header{
    @media ${device.laptopL} {
      height: 88px;
    }
  }
  .ant-menu{
    background-color: transparent;
  }
  .ant-menu-item-selected a{
    color: ${props => props.theme.colors.primary};
  }
  .ant-menu-item a:hover{
    color: ${props => props.theme.colors.primary};
  }
  .ant-layout-sider-zero-width-trigger-left{
    display: none;
}
`

const StyledLayout = styled(Layout)`
  width: 100%;
  padding: 0;
`
const StyledHeader = styled(Header)`
  background-color: ${props => props.theme.colors.secondary};
  display: flex;
  flex-directon: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  @media ${device.laptopL} {
    padding: 0 12px;
  }
`

const StyledFooter = styled(Footer)`
  background-color: ${props => props.theme.colors.secondary};
  padding: 12px 10px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  @media ${device.laptopL} {
    padding: 24px 24px;
  }
`

const StyledContent = styled(Content)`
  margin: 10px;
`

const Container = styled.div`
  padding: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  @media ${device.laptopL} {
    padding: 30px;
  }
`

const StyledImg = styled.img`
  visibility: ${props => props.$error ? 'hidden' : 'normal'};
  height: 85%;
  @media ${device.laptopL} {
    max-height: 70px;
    height: 75%;
  }
`

const FooterLogo = styled.img`
  height: 20px;
  @media ${device.laptopL} {
    height: 35px;
  }
`

const HamburgerButton = styled.div`
  margin-right: 15px;
  font-size: 180%;
  @media ${device.laptop} {
    display: none;
  }
`
const CookiePreference = styled.button`
  color: ${props => props.theme.colors.fontColorSecondary};
  outline: none;
  border: none;
  background: transparent;
  font-size: 13px;
  cursor: pointer;
  &:active, &:focus, &:hover {
    opacity: 0.7;
  }
  &:hover {
    text-decoration: none;
  }
`