import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { MainLayout } from '../../layouts/MainLayout'
import { device } from '../../style/breakpoints/device'
import { useCompany } from '../../context/CompanyProvider';
import i18n from '../../i18n';
import { LANGUAGE_DEFAULT } from '../../utils/Constants';
import { Orders } from '../Orders/Orders';

export const Dashboard = () => {
  const { t } = useTranslation();
  const company = useCompany()
  const [greeting, setGreeting] = useState('')

  useEffect(() => {
    company.getCompany().then((data) => {
      if (data && data.greeting) {
        const greetingObj = JSON.parse(data.greeting)
        const greetingText = greetingObj[i18n.language]
        if (greetingText && greetingText !== "<p><br></p>") {
          setGreeting(greetingText)
        } else {
          setGreeting(greetingObj[LANGUAGE_DEFAULT])
        }
      }
    })
  }, [company])
  return (
    <MainLayout>
        {
          greeting ?
            <div className="ql-editor" style={{ color: '#000' }} dangerouslySetInnerHTML={{ __html: greeting }} /> :
            <>
              <h3>{t('dashboard.title')}</h3>
              <p>Dashboard Content</p>
            </>
        }
      <Orders selectorMode reservationList />
    </MainLayout>
  )
}


