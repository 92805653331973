import React from 'react'
import OrderForm from '../../elements/Form/OrderForm'
import { MainLayout } from '../../layouts/MainLayout'

export const AddOrder = () => {
  return (
    <MainLayout>
      <OrderForm />
    </MainLayout>
  )
}
