import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { colors as defaultColors } from '../default/colors'
import { useCompany } from '../../../context/CompanyProvider'
import { getContrastColor } from '../../../utils/ColorUtils'

export const DynamicThemeProvider = (props) => {
  const { children } = props
  const company = useCompany()
  const [colors, setColors] = useState(defaultColors)

  useEffect(() => {
    const fetchCompany = async () => {
      const companyData = await company.getCompany()
      if (companyData) {
        setColors({
          primary: companyData.mainColor,
          secondary: companyData.secondColor,
          fontColor: companyData.fontColor,
          fontColorSecondary: getContrastColor(companyData.secondColor),
          fontColorPrimary: getContrastColor(companyData.mainColor),
        })
      }
    }
    fetchCompany()
  }, [company])


  return (
    <ThemeProvider theme={{ colors: colors }}>
      {children}
    </ThemeProvider>
  )
}