import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { MainLayout } from '../../layouts/MainLayout'
import { device } from '../../style/breakpoints/device'
import { Select, Space, Table, Form, Input, DatePicker, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { getFromAPI, postToAPI } from '../../context/API';
import i18n from '../../i18n';
import {
  InfoCircleOutlined,
  EditOutlined,
  CopyOutlined,
  PrinterOutlined,
  DeleteOutlined,
  VerticalAlignBottomOutlined
} from '@ant-design/icons';
import _uniqueId from 'lodash/uniqueId';
import { LANGUAGE_ID_DEFAULT, LANGUAGE_ID_MAP } from '../../utils/Constants'
import { OrderModal } from '../../elements/Modal/OrderModal';
import { useAuth } from '../../context/AuthProvider';
import { sortByLanguage } from '../../utils/DataUtils';
import { hasAccessToRoute } from '../../utils/RoleUtils';
import { dateSorter, alphaNumericSort } from '../../utils/Sorters';
import { filterDataByCostCenter } from '../../utils/FilterUtils';

import ReactToPrint from 'react-to-print';
import { OrderPrint } from '../../print/OrderPrint'
import { DeleteModal } from '../../elements/Modal/DeleteModal';
import { openNotificationWithIcon } from '../../utils/Notification'
import Tooltip from '../../elements/Tooltip/Tooltip';

/// ToDo: Uncomment the filters when API has ability to search with those

const { Option } = Select;

export const Orders = (props) => {
  const { selectorMode, onSelect, reservationList } = props
  const { t } = useTranslation();
  const [orderList, setOrderList] = useState([])
  const [users, setUsers] = useState([])
  const [statusses, setStatusses] = useState([])
  const [selectedDate, setSelectedDate] = useState()
  const [selectedStatus, setSelectedStatus] = useState()
  const [selectedUser, setSelectedUser] = useState()
  const [types, setTypes] = useState([])
  const auth = useAuth()
  const [editOrder, setEditOrder] = useState()
  const [previewOrder, setPreviewOrder] = useState()
  const [orderPrint, setOrderPrint] = useState()
  const [itemListPrint, setItemListPrint] = useState()
  // const [itemListData, setItemListData] = useState()
  const [priceList, setPriceList] = useState({})
  const [orderPrintPromise, setOrderPrintPromise] = useState()
  const [tableLoading, setTableLoading] = useState(true)
  const [searchNumber, setSearchNumber] = useState()
  const [numberTimer, setNumberTimer] = useState()
  const [searchName, setSearchName] = useState()
  const [nameTimer, setNameTimer] = useState()
  const [searchItemName, setSearchItemName] = useState()
  const [itemNameTimer, setItemNameTimer] = useState()
  const [fetchController, setFetchController] = useState()
  const [isEnlarged, setIsEnlarged] = useState(false)

  const [costCenterList, setCostCenterList] = useState([])
  const [selectedCostCenter, setSelectedCostCenter] = useState()
  const [selectedContact, setSelectedContact] = useState()
  const [contactTimer, setContactTimer] = useState()
  const [selectedCreatedAt, setSelectedCreatedAt] = useState()
  const [selectedCreator, setSelectedCreator] = useState()
  const [creatorTimer, setCreatorTimer] = useState()

  const [deleteOrder, setDeleteOrder] = useState()

  const printRef = useRef();

  const langID = LANGUAGE_ID_MAP[i18n.language]

  const defaultDATE = '01.01.1900'

  const updateOrders = async () => {
    setTableLoading(true)
    const query = {
      Offset: encodeURIComponent(0)
    }
    const requestedFields = {
      fields: ['intOrderID',
        'intOrderNo',
        'intOrderTyp',
        'strOrderTitle',
        'dtmExecutionDate',
        'dtmOrderDate',
        'intOrderUserID',
        'strCostCenter',
        'Item'
      ]
    }
    query.requested = JSON.stringify(requestedFields)
    // if (searchNumber) {
    //   query.intOrderNo = encodeURIComponent(searchNumber)
    // }
    if (selectedCreatedAt) {
      query.dtmOrderDate = encodeURIComponent(selectedCreatedAt)
    }
    if (selectedCreator) {
      query.strCustomerOrderNo1 = encodeURIComponent(selectedCreator)
    }
    if (selectedContact) {
      // query.strContactFax = encodeURIComponent(selectedContact)
      query.strContacPerson = encodeURIComponent(selectedContact)
      // query.strContactEMail = encodeURIComponent(selectedContact)
      // query.strName1 = encodeURIComponent(selectedContact)
      // query.strName2 = encodeURIComponent(selectedContact)
    }
    if (selectedDate) {
      query.dtmExecutionDate = encodeURIComponent(selectedDate)
    }
    if (selectedStatus) {
      query.intOrderStatus = encodeURIComponent(selectedStatus)
    }
    if (reservationList) {
      query.intOrderStatus = encodeURIComponent(14)
    }


    let url = 'order/get?' + Object.keys(query).map((k) => {
      return `${k}=${query[k]}`
    }).join('&')

    if (tableLoading && fetchController) {
      fetchController.abort()
      setFetchController()
    }
    const controller = new AbortController();
    // signal to pass to fetch
    const signal = controller.signal;
    setFetchController(controller)
    const requestedFieldsItems = {
      fields: ['intItemCatID',
        'intItemGrpID',
        'decQtyInStock',
        'decQtyReserved',
        'decWidth',
        'decLength',
        'decHeight']
    }
    const urlQuery = {}
    urlQuery.requested = JSON.stringify(requestedFieldsItems)
    urlQuery.strSearch = searchItemName
    const urlItem = `item/get?strSearch=${urlQuery.strSearch || ''}&requested=${urlQuery.requested}&Offset=0`
    const intialItemUrl = urlItem + "&PageSize=1"

    const itemList = await getFromAPI(intialItemUrl, {}, auth, signal)
      .then(res => res.json())
      .then(async (response) => {
        const fullItemUrl = urlItem + `&PageSize=${response?.total || 500}`;
          const data = await getFromAPI(fullItemUrl, {}, auth, signal)
            .then(res => res.json())
            .then(data => {
               const itemData =
                  data.response.map((item, index) => {
                    item.key = index
                    if (!item.strName) {
                      return item
                    }
                    const name =
                      item.strName.find(strName => strName.intLanguageID === langID) ||
                      item.strName.find(
                        strName => strName.intLanguageID === LANGUAGE_ID_DEFAULT
                      )
                    item.dimensions = `${item.decWidth} / ${item.decLength} / ${item.decHeight}`
                    item.name = name && name.strText
                    
                    return item
                  })
                  // setItemListData(itemData);
                  return itemData;
            }).catch(_ => [])
            return data;
        })
      .catch(_ => [])
    getFromAPI(url, {}, auth, signal).then(res => res.json()).then(data => {
      const costCenterFilteredData = filterDataByCostCenter(data.response, selectedCostCenter);
      const nameFilteredData = searchName ? costCenterFilteredData.filter(item =>
        item.strOrderTitle && item.strOrderTitle.match(new RegExp(searchName, "i"))) :
        costCenterFilteredData
      const numberFilteredData = searchNumber ? nameFilteredData.filter(item =>
        item.intOrderNo && item.intOrderNo.match(new RegExp(searchNumber, "i"))) : nameFilteredData
      // const items = itemListData && itemListData.length ? itemListData : itemList;
      const itemFilteredData = searchItemName ? numberFilteredData.filter(order => {
        return order?.Item?.some(orderItem => {
          return itemList.some(item => `${item.intItemID}` === `${orderItem.intItemID}`)
        })
      })
        : numberFilteredData
      const fetchedList = itemFilteredData.map((item, index) => {
        const orderStatus = statusses.find(type => `${type.intValue}` === item.intOrderStatus)
        if (orderStatus) {
          const orderStatusName =
            orderStatus.strTypeName.find(strTypeName => strTypeName.intLanguageID === langID) ||
            orderStatus.strTypeName.find(
              strTypeName => strTypeName.intLanguageID === LANGUAGE_ID_DEFAULT
            )
          item.orderStatus = orderStatusName.strText
        }
        if (item.dtmExecutionDate && item.dtmExecutionDate.includes(' ')) {
          item.dtmExecutionDate = item.dtmExecutionDate.split(' ')[0]
        }
        if (item.dtmOrderDate && item.dtmOrderDate.includes(' ')) {
          item.dtmOrderDate = item.dtmOrderDate.split(' ')[0]
        }

        if (item.dtmOrderDate && item.dtmOrderDate === defaultDATE) {
          item.dtmOrderDate = ''
        }

        if (item.dtmExecutionDate && item.dtmExecutionDate === defaultDATE) {
          item.dtmExecutionDate = ''
        }
        item.key = index
        return item
      })
      fetchedList.sort((a, b) => a && b && a.dtmOrderDate && b.dtmOrderDate && dateSorter(a.dtmOrderDate, b.dtmOrderDate)).reverse()
      setOrderList(fetchedList)
      setTableLoading(false)
    }).catch((err) => {
      if (err.name !== 'AbortError') {
        setTableLoading(false)
        setOrderList([])
      }
    })
  }

  useEffect(() => {
    if (orderPrint && itemListPrint && itemListPrint.length && orderPrintPromise) {
      orderPrintPromise()
    }

  }, [orderPrint, orderPrintPromise, itemListPrint])

  const getPrices = async (items, loadingAddress, deliveryAddress) => {

    const query = {
      strZIPFrom: loadingAddress['strZIP'],
      strZIPTo: deliveryAddress['strZIP'],
      strCityFrom: loadingAddress['strCity'],
      strCityTo: deliveryAddress['strCity'],
      strAddress1From: loadingAddress['strAddress1'],
      strAddress1To: deliveryAddress['strAddress1'],
      positions: JSON.stringify({
        items: items.map((item, index) => (
          {
            intPosition: index,
            intItemID: item.intItemID,
            decQty: item.decQty,
            intOrderTyp: +item.intOrderTyp
          }
        ))
      })
    }
    let url =
      'item/getItemPrice?' +
      Object.keys(query)
        .map(k => {
          return `${k}=${encodeURIComponent(query[k])}`
        })
        .join('&')
    if (url.includes('undefined')) {
      return
    }

    return await getFromAPI(url, {}, auth)
      .then(res => res.json())
      .then(data => {
        const totalPrice = data.response?.reduce((a, b) => a + b.decAmount, 0)
        const response = data.response.map(item => ({ ...item, source: items[item.intPosition] }))
        return { totalPrice, prices: response }
      }).catch(() => ({}))
  }

  const getCostCenter = async () => {
    return await getFromAPI('order/getCostCenter', {}, auth).then(res => res.json()).then(data => {
      if (data.response) {
        setCostCenterList(alphaNumericSort(data.response));
      } else {
        setCostCenterList([]);
      }
    })
  }

  const loadPrintData = async (id) => {
    if (!id) return false
    return await getFromAPI('order/get?intOrderID=' + id, {}, auth).then(res => res.json()).then(async data => {
      if (data.response) {
        let orderObj = data.response[0]

        const updatedOrderTypes = await updateTypes()
        const updatedOrderStatusses = await updateStatusses()

        const orderStatus = updatedOrderStatusses && updatedOrderStatusses.find(orderStatus => `${orderStatus.intValue}` === orderObj.intOrderStatus)

        if (orderStatus) {
          orderObj['orderStatusTextName'] = orderStatus.strTypeName[0].strText
        } else {
          orderObj['orderStatusTextName'] = undefined
        }

        if (orderObj.Item && orderObj.Item.length) {

          const uniqueItems = orderObj.Item.map(async item => {
            const url = 'item/get?intItemID=' + item.intItemID
            const fetchedItem = await getFromAPI(url, {}, auth)
              .then(res => res.json())
              .then(data => {
                return data.response && data.response[0]
              })

            let name
            if (fetchedItem) {
              name =
                fetchedItem.strName.find(strName => strName.intLanguageID === langID) ||
                fetchedItem.strName.find(
                  strName => strName.intLanguageID === LANGUAGE_ID_DEFAULT
                )
            }
            const orderType = updatedOrderTypes && updatedOrderTypes.find(type => `${type.intValue}` === `${item.intOrderTyp}`)
            if (orderType) {
              const orderTypeName =
                orderType.strTypeName.find(strTypeName => strTypeName.intLanguageID === langID) ||
                orderType.strTypeName.find(
                  strTypeName => strTypeName.intLanguageID === LANGUAGE_ID_DEFAULT
                )
              item.orderType = orderTypeName.strText

            }
            const returnItem = { ...item, ...fetchedItem, key: _uniqueId('prefix-'), name: name && name.strText }
            return returnItem
          })


          const uniqueItemsList = await Promise.all(uniqueItems)
          const loadingAddress = (orderObj && orderObj.Address.find(address => address.intType === 1)) || {}
          const deliveryAddress = (orderObj && orderObj.Address.find(address => address.intType === 2)) || {}

          const priceList = await getPrices(uniqueItemsList, loadingAddress, deliveryAddress)
          setPriceList(priceList)
          setItemListPrint(uniqueItemsList)

        }
        setOrderPrint(orderObj)

        const promiseVar = new Promise((resolve) => setOrderPrintPromise(resolve))


        await promiseVar

        await new Promise(resolve => setTimeout(resolve, 500));
      }
    })
  };

  const updateStatusses = async () => {
    return await getFromAPI('type/getType?intParentID=1092', {}, auth).then(res => res.json()).then(data => {
      if (data.response) {
        const updatedStatusses = data.response.map((item, index) => {
          item.key = index
          return item
        })
        setStatusses(sortByLanguage(updatedStatusses, 'strTypeName'))
        return updatedStatusses
      }
    })
  }

  const updateTypes = async () => {
    return await getFromAPI('type/getType?intParentID=1087', {}, auth).then(res => res.json()).then(data => {
      if (data.response) {
        const updatedTypes = data.response.map((item, index) => {
          item.key = index
          return item
        })
        setTypes(sortByLanguage(updatedTypes, 'strTypeName'))
        return updatedTypes
      }
    })
  }

  const updateUsers = () => {
    let url = 'auth/getUserList'
    getFromAPI(url, {}, auth, fetchController?.signal)
      .then(res => res.json())
      .then(data => {
        if (data.response) {
          setUsers(
            data.response.map((item, index) => {
              item.key = index
              return item
            })
          )
        } else {
          setUsers([])
        }
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setUsers([])
        }
      })
  }

  const onDeleteError = (status) => {
    if (status === 400) {
      openNotificationWithIcon(
        'error',
        t('order.onFail.title'),
        t('order.onFail.description')
      )
    } else if (status === 500){
      openNotificationWithIcon(
        'error',
        t('login.loginError.title'),
        t('login.loginError.serverError')
      )
    }
  }

  const onDeleteOrder = (data) => {
    let url = 'order/delete'
    const { intOrderID, intOrderNo} = data;

    postToAPI(url, {intOrderID, intOrderNo}, false, auth)
      .then(res => res.json())
      .then(data => {
        if (data.code === 200) {
          openNotificationWithIcon(
            'success',
            t('orders.onDelete.success.title'),
            t('orders.onDelete.success.description')
          )
          updateOrders()
          setDeleteOrder()
        } else {
          onDeleteError(data.code)
        }
      })
      .catch((err) => {
        const result = err.response?.status
        onDeleteError(result)
      })
  }


  const handleNumberChange = change => {
    if (!searchNumber && change.length < 3) {
      return
    }
    setTableLoading(true)
    if (numberTimer) {
      clearTimeout(numberTimer);
      setNumberTimer(null);
    }
    setNumberTimer(
      setTimeout(() => {
        setSearchNumber(change.length >= 3 ? change : '')
      }, 750)
    );
  }
  const handleNameChange = change => {
    if (!searchName && change.length < 3) {
      return
    }
    setTableLoading(true)
    if (nameTimer) {
      clearTimeout(nameTimer);
      setNameTimer(null);
    }
    setNameTimer(
      setTimeout(() => {
        setSearchName(change.length >= 3 ? change : '')
      }, 750)
    );
  }

  const handleItemNameChange = change => {
    if (!searchItemName && change.length < 3) {
      return
    }
    setTableLoading(true)
    if (itemNameTimer) {
      clearTimeout(itemNameTimer);
      setItemNameTimer(null);
    }
    setItemNameTimer(
      setTimeout(() => {
        setSearchItemName(change.length >= 3 ? change : '')
      }, 750)
    );
  }

  const handleCreatorChange = change => {
    if (!selectedCreator && change.length < 3) {
      return
    }
    setTableLoading(true)
    if (creatorTimer) {
      clearTimeout(itemNameTimer);
      setCreatorTimer(null);
    }
    setCreatorTimer(
      setTimeout(() => {
        setSelectedCreator(change.length >= 3 ? change : '')
      }, 750)
    );
  }

  const handleContactChange = change => {
    if (!selectedContact && change.length < 3) {
      return
    }
    setTableLoading(true)
    if (contactTimer) {
      clearTimeout(contactTimer);
      setContactTimer(null);
    }
    setContactTimer(
      setTimeout(() => {
        setSelectedContact(change.length >= 3 ? change : '')
      }, 750)
    );
  }

  useEffect(() => {
    updateStatusses()
    updateTypes()
    updateUsers()
    if (!costCenterList.length) {
      getCostCenter()
    }
  }, [])

  useEffect(() => {
    if (statusses && types) {
      updateOrders()
    }
  }, [searchName, searchNumber, statusses, types, selectedDate, selectedStatus, searchItemName, selectedUser, selectedContact, selectedCostCenter, selectedCreator, selectedCreatedAt])

  const columns = [
    {
      title: t('orders.title'),
      dataIndex: 'strOrderTitle',
      sorter: (a, b) => a.strOrderTitle.localeCompare(b.strOrderTitle),
    },
    {
      title: t('orders.orderNumber'),
      dataIndex: 'intOrderNo',
      sorter: (a, b) => a.intOrderNo - b.intOrderNo,
      responsive: ["md"]
    },
    {
      title: t('orders.createdAt'),
      defaultSortOrder: 'descend',
      dataIndex: 'dtmOrderDate',
      sorter: (a, b) => a && b && a.dtmOrderDate && b.dtmOrderDate && dateSorter(a.dtmOrderDate, b.dtmOrderDate),
      responsive: ["md"]
    },
    {
      title: (<Space>
        <span>{t('orders.execution')}</span>
        <Tooltip content={t('orders.executionTooltip')} placement='top' />
      </Space>),
      dataIndex: 'dtmExecutionDate',
      sorter: (a, b) => a && b && a.dtmExecutionDate && b.dtmExecutionDate && dateSorter(a.dtmExecutionDate, b.dtmExecutionDate),
      responsive: ["md"],
    },
    {
      title: t('orders.status'),
      dataIndex: 'orderStatus',
      sorter: (a, b) => a.orderStatus.localeCompare(b.orderStatus),
      responsive: ["sm"],
    },
    {
      title: t('items.actions'),
      key: 'action',
      render: (_, record) => (
        <Space size="small">
          {(!selectorMode || reservationList) &&
            hasAccessToRoute('/orders/create', auth) &&
            record.intOrderStatus < 21 &&
            <EditOutlined onClick={() => setEditOrder(record)} />}
          {/* <DeleteOutlined /> */}
          <InfoCircleOutlined onClick={() => setPreviewOrder(record)} />
          <ReactToPrint
            trigger={() => <PrinterOutlined />}
            content={() => printRef.current}
            onBeforeGetContent={async () => {
              await loadPrintData(record.intOrderID)
              return Promise.resolve()
            }}
            onAfterPrint={() => {
              setOrderPrint()
              setOrderPrintPromise()
              setItemListPrint()
            }}


          />
          {selectorMode && !reservationList && <CopyOutlined onClick={() => onSelect && onSelect(record)} />}
          {record && ["14", "15"].includes(""+record.intOrderStatus) && <DeleteOutlined onClick={() => setDeleteOrder(record)} />}
        </Space>
      ),
    }
  ];
  const OrderPageWrapper = selectorMode ? SelectorLayout : MainLayout
  return (
    <OrderPageWrapper $selectorMode={selectorMode}>
      <div>
        {!reservationList && <SearchBar colon={false} labelWrap 
        $isEnlarged={isEnlarged}
        >
          <Row>
            <Col span={24} md={{ span: 8, offset: 2 }}>
              <Form.Item
                name='orderTitle'
                label={t('orders.nameSearchTitle')}
                labelCol={{ flex: '130px' }}
                labelAlign='right'
              >
                <Input
                  onChange={(e) => handleNameChange(e.target.value)}
                  type="text"
                />
              </Form.Item>
            </Col>
            <Col span={24} md={{ span: 8, offset: 2 }}>
              <Form.Item
                name='number'
                label={t('orders.orderNumber')}
                labelCol={{ flex: '130px' }}
                labelAlign='right'
              >
                <Input
                  onChange={(e) => handleNumberChange(e.target.value)}
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} md={{ span: 8, offset: 2 }} className='mobile-resize-space'>
              <Form.Item
                name='strCustomerOrderNo1'
                label={t('orders.strCustomerOrderNo')}
                labelCol={{ flex: '130px' }}
                labelAlign='right'
              >
                <Input
                  onChange={(e) => handleCreatorChange(e.target.value)}
                  type="text"
                />
              </Form.Item>
            </Col>
            <Col span={24} md={{ span: 8, offset: 2 }}>
            <Form.Item
                name="dtmOrderDate"
                label={t('orders.createdAt')}
                labelCol={{ flex: '130px' }}
                labelAlign='right'
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format='DD.MM.YYYY'
                  placeholder={t('orders.selectDate')}
                  onChange={date => setSelectedCreatedAt(date && date.format('YYYY-MM-DD'))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className='tablet-resize-space'>
            <Col span={24} md={{ span: 8, offset: 2 }}>
              <Form.Item
                name="dtmExecutionDate"
                label={t('orders.execution')}
                labelCol={{ flex: '130px' }}
                labelAlign='right'
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format='DD.MM.YYYY'
                  placeholder={t('orders.selectDate')}
                  onChange={date => setSelectedDate(date && date.format('YYYY-MM-DD'))}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={{ span: 8, offset: 2 }}>
              <Form.Item
                label={t('orders.status')}
                labelCol={{ flex: '130px' }}
                labelAlign='right'
              >
                <Select
                  showSearch
                  allowClear
                  placeholder={t('items.selectPlaceholder')}
                  optionFilterProp="children"
                  onSelect={(item) => setSelectedStatus(item)}
                  onClear={() => setSelectedStatus()}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                >
                  {statusses.map((status, index) => {
                    const text = status.strTypeName.find(
                      name => name.intLanguageID === langID
                    ) || status.strTypeName.find(
                      name => name.intLanguageID === LANGUAGE_ID_DEFAULT
                    )
                    return <Option key={index} value={status.intValue}>{text ? text.strText : `${status.intValue}`}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} md={{ span: 8, offset: 2 }}>
              <Form.Item
                name='itemName'
                label={t('items.itemName')}
                labelCol={{ flex: '130px' }}
                labelAlign='right'
              >
                <Input
                  onChange={(e) => handleItemNameChange(e.target.value)}
                  type="text"
                />
              </Form.Item>
            </Col>
            {/* <Col span={24} md={{ span: 8, offset: 2 }}>
              <Form.Item
                label={t('orders.strCustomerOrderNo')}
                labelCol={{ flex: '130px' }}
                labelAlign='right'
              >
                <Select
                  showSearch
                  allowClear
                  placeholder={t('items.selectPlaceholder')}
                  optionFilterProp="children"
                  onSelect={(userId) => setSelectedUser(userId)}
                  onClear={() => setSelectedUser()}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                >
                  {users.map((user, index) => {
                    return <Option key={index} value={user.UserId}>{`${user.strFirstName} ${user.strName} (${user.Email})`}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col> */}
            <Col span={24} md={{ span: 8, offset: 2 }}>
              <Form.Item
                label={t('orders.costCenter')}
                labelCol={{ flex: '130px' }}
                labelAlign='right'
              >
                <Select
                  showSearch
                  allowClear
                  placeholder={t('items.selectPlaceholder')}
                  optionFilterProp="children"
                  onSelect={(data) => setSelectedCostCenter(data)}
                  onClear={() => setSelectedCostCenter()}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option) => option.value.toUpperCase().indexOf(input.toUpperCase()) !== -1}
                >
                  {costCenterList.map((costCenter, index) => {
                    return <Option key={index} value={costCenter.value}>{costCenter.value}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} md={{ span: 8, offset: 2 }}>
              <Form.Item
                name='contact'
                label={t('orders.contact')}
                labelCol={{ flex: '130px' }}
                labelAlign='right'
              >
                <Input
                  onChange={(e) => handleContactChange(e.target.value)}
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
          <ResizeButton $isEnlarged={isEnlarged} onClick={() => setIsEnlarged(prev =>  !prev)}>
            <ResizeText>{isEnlarged ? t('orders.shrink') : t('orders.enlarge')}</ResizeText>
            <EnlargeIcon style={{ fontSize: "22px" }}  $isEnlarged={isEnlarged} className />
          </ResizeButton>
        </SearchBar>}
        <Table columns={columns} dataSource={orderList} showSorterTooltip={false} loading={tableLoading} scroll={{ x: "scroll" }} />
        <OrderModal
          visible={editOrder || previewOrder}
          previewMode={previewOrder}
          afterClose={() => { setEditOrder(); setPreviewOrder() }}
          onSubmit={() => { setEditOrder(); setPreviewOrder(); updateOrders() }}
          values={editOrder || previewOrder}
          title={editOrder ? t('orders.editOrder') : t('orders.previewOrder')} />

        <OrderPrint orderPrint={orderPrint} itemListPrint={itemListPrint} ref={printRef} priceList={priceList} />

        <DeleteModal 
          title={t('orders.onDelete.title')}
          description={t('orders.onDelete.description', {order: `${deleteOrder?.intOrderNo}(${deleteOrder?.strOrderTitle})`})}okText={t('orders.onDelete.button')}
          cancelText={t('modals.cancel')}
          visible={!!deleteOrder}
          onSubmit={() => {
            onDeleteOrder(deleteOrder)
          }}
          afterClose={() => {
            setDeleteOrder()
          }}
        />
      </div>
    </OrderPageWrapper>
  )
}

const SearchBar = styled(Form)`
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  height: ${props => props.$isEnlarged ? '100%' : '175px'};
  overflow: hidden;

  .tablet-resize-space {
    @media ${device.tablet} {
      margin-bottom: ${props => !props.$isEnlarged ? '10px' : '0'};
    }
  }

  .mobile-resize-space {
    margin-bottom: ${props => !props.$isEnlarged ? '10px' : '0'};
    @media ${device.tablet} {
      margin-bottom: 0;
    }
  }

  @media ${device.tabletS} {
    height: ${props => props.$isEnlarged ? '100%' : '200px'};
    .ant-form-item .ant-form-item-label {
      flex: 0 0 100% !important;
      max-width: 100%;
    }
  }
`
const EnlargeIcon = styled(VerticalAlignBottomOutlined)`
  transform: ${props => props.$isEnlarged ? 'scaleY(-1)' : 'scaleY(1)'};
`
const ResizeText = styled.span`
  font-size: 14px;
  margin-right: 5px;
  color: #000;
`
const ResizeButton = styled.div`
  position: absolute;
  bottom: ${props => props.$isEnlarged ? '-2px' : '0px'};
  left: 45%;
  justify-content: center;
  align-items: flex-end;
  z-index: 2;
  cursor: pointer;
  display: flex;

  @media ${device.tabletS} {
   left: 0;
   right: 0;
  }


  &:hover {
    .anticon {
      transform: ${props => props.$isEnlarged ? 'translateY(-2px) scaleY(-1)' : 'translateY(2px) scaleY(1)'};
      transition: transform 0.2s ease-in-out;
    }
  }
`
const SelectorLayout = styled.div``
