import { ConfigProvider } from 'antd'
import React from 'react'
import i18n from '../i18n'
import en_GB from 'antd/es/locale/en_GB';
import it_IT from 'antd/es/locale/it_IT';
import fr_FR from 'antd/es/locale/fr_FR';
import de_DE from 'antd/es/locale/de_DE';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/it';
import 'moment/locale/fr';
import 'moment/locale/de';
import { defaultTheme } from '../style/theme/default';

export const languageMapping = {
  en: en_GB,
  it: it_IT,
  de: de_DE,
  fr: fr_FR
}

moment.locale(i18n.language, {
  week: {
    dow: 1, // Start week on monday
  },
});

const AntProvider = ({ children }) => {
  return (
    <ConfigProvider locale={languageMapping[i18n.language]} theme={defaultTheme}>
      {children}
    </ConfigProvider>
  )
}

export default AntProvider