import React, { createContext, useState, useContext, useEffect } from 'react'
import { COOKIE_OPTIONS } from '../utils/Constants'

const CookieConsentContext = createContext()
const defaultChecked = ['Essential']

const CookieConsentProvider = props => {
  useEffect(() => {
    if(!localStorage.getItem('WF-CookieConsent')) {
      setConsentGiven(false);
    }
  }
  ,[])
  const [consentGiven, setConsentGiven] = useState(
    localStorage.getItem('WF-CookieConsent') !== undefined
  );
  const [checkedList, setCheckedList] = useState(defaultChecked);

  const handleConsent = () => {
    localStorage.setItem('WF-CookieConsent', 'true');
    COOKIE_OPTIONS.forEach(item => {
      localStorage.setItem(`WF-${item}Cookies`, `true`);
    })
    setConsentGiven(true);
  };

  const openConsentBanner = () => {
    setConsentGiven(false);
  };
  
  const handleSelectedConsent = () => {
    COOKIE_OPTIONS.forEach(item => {
      localStorage.setItem(`WF-${item}Cookies`, `${checkedList.includes(item)}`);
    })
    localStorage.setItem('WF-CookieConsent', COOKIE_OPTIONS.length === checkedList.length ? 'true' : 'limited');
    setConsentGiven(true);
  };

  const handleDecline = () => {
    localStorage.setItem('WF-CookieConsent', 'false');
    COOKIE_OPTIONS.forEach(item => {
      localStorage.removeItem(`WF-${item}Cookies`)
    })
    setConsentGiven(true);
  };

  const onOptionsChange = (list) => {
    setCheckedList(list);
  }

  return (
    <CookieConsentContext.Provider
      value={{ handleDecline, handleConsent, handleSelectedConsent, consentGiven, onOptionsChange, openConsentBanner, checkedList }}
      {...props}
    />
  )
}

const useCookieConsent = () => useContext(CookieConsentContext)

export { CookieConsentProvider, useCookieConsent }