import React from 'react'
import { Modal } from 'antd';
import styled from 'styled-components';
import { device } from '../../style/breakpoints/device';
import {
  WarningFilled
} from '@ant-design/icons'; 

export const DeleteModal = (props) => {
  const { visible, afterClose, title, description, onSubmit, okText, cancelText } = props
  return (
    <ModalWrapper
      title={title}
      open={visible}
      onCancel={afterClose}
      onOk={onSubmit}
      destroyOnClose
      content={description}
      okText={okText}
      cancelText={cancelText}
      okType="danger"
      icon={<WarningFilled style={{ color: 'red' }} />}
    >{description}</ModalWrapper>
  )
}
const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    height: fit-content;
    @media ${device.tablet} {
      height: initial;
    }
  }
`
