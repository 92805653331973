import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MainLayout } from '../../layouts/MainLayout'
import { Select, Space, Table, Form, Input, Checkbox, Button, Avatar, Popover, Spin, Image } from 'antd'
import { useTranslation } from 'react-i18next'
import { device } from '../../style/breakpoints/device'
import { getFromAPI } from '../../context/API'
import {
  InfoCircleOutlined,
  EditOutlined,
  FileAddOutlined
} from '@ant-design/icons'
import { ItemModal } from '../../elements/Modal/ItemModal'
import { LANGUAGE_ID_DEFAULT, LANGUAGE_ID_MAP } from '../../utils/Constants'
import { sortByLanguage } from '../../utils/DataUtils'
import { useAuth } from '../../context/AuthProvider'
import { hasAccessToRoute, READ_ONLY_ROLES } from '../../utils/RoleUtils'
import { exportToSheet } from '../../utils/SheetUtils'
import { useCompany } from '../../context/CompanyProvider';
import {
  useQuery,
} from '@tanstack/react-query'

const { Option } = Select


export const Items = props => {
  const { selectorMode, onSelect } = props
  const { t, i18n } = useTranslation()
  // const [itemList, setItemList] = useState([])
  // const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState()
  const [groups, setGroups] = useState()
  const [selectedGroup, setSelectedGroup] = useState()
  const [editItem, setEditItem] = useState()
  const [previewItem, setPreviewItem] = useState()
  // const [tableLoading, setTableLoading] = useState(true)
  const auth = useAuth()
  // const [fetchController, setFetchController] = useState()
  const [numberTimer, setNumberTimer] = useState(null)
  const [searchNumber, setSearchNumber] = useState()
  const [nameTimer, setNameTimer] = useState(null)
  const [searchName, setSearchName] = useState()
  const [availableItem, setAvailableItem] = useState(true)
  const [exportEnable, setExportEnable] = useState(true)
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);
  const [sortedInfo, setSortedInfo] = useState({ field: 'strItemNo', order: 'descend' });
  const [isItemImageVisible, setItemImageVisibility] = useState({ value: false, updated: false });
  const [isItemsPriceVisible, setItemsPriceVisibility] = useState({ value: false, updated: false });
  const [selectedItem, setSelectedItem] = useState({
    intItemPictureID: null,
    intItemID: null,
  });
  const [trigger, setTrigger] = useState("hover")

  const FIELD_MAP = {
    'dimensions': 'decWidth',
    'name': `strText${i18n.language[0].toUpperCase()}`,
  }

  const company = useCompany()

  const langID = LANGUAGE_ID_MAP[i18n.language]

  const getUrlWithParams = (requestedFields, offset, size) => {
    const query = {
      Offset: encodeURIComponent(offset || pageOffset),
      PageSize: encodeURIComponent(size || pageSize),
    }
    if (sortedInfo.field && sortedInfo.order) {
      query['strSortAttribute'] = FIELD_MAP[sortedInfo.field] || sortedInfo.field
      query['strSortDirection'] = sortedInfo.order === 'ascend' ? 'ASC' : 'DESC'
    }

    if (isItemImageVisible) {
      query.intThumbSize = encodeURIComponent(40)
    }
    if (selectedGroup) {
      query.intItemGrpID = encodeURIComponent(selectedGroup)
    }
    if (searchNumber) {
      query.strItemNo = encodeURIComponent(searchNumber)
    }
    if (searchName) {
      query.strSearch = encodeURIComponent(searchName)
    }
    if (selectedCategory) {
      query.intItemCatID = encodeURIComponent(selectedCategory)
    }
    if (availableItem) {
      query.IsAvailable = encodeURIComponent(1)
    }
    if (requestedFields) {
      query.requested = JSON.stringify(requestedFields)
    }
    let url =
      'item/get?' +
      Object.keys(query)
        .map(k => {
          return `${k}=${query[k]}`
        })
        .join('&')
    return url
  }

  const controller = new AbortController()
  // signal to pass to fetch
  const signal = controller.signal

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['items', pageSize, pageOffset, selectedCategory, selectedGroup, searchNumber, searchName, availableItem, isItemImageVisible, groups, sortedInfo],
    queryFn: (async () => {
      if (!groups) {
        return {}
      }
      const requestedItemFields = {
        fields: ['intItemCatID',
          'intItemGrpID',
          'decQtyInStock',
          'decQtyAvailable',
          'decQtyReserved',
          'decWidth',
          'decLength',
          'decHeight']
      }
      if (isItemImageVisible) {
        requestedItemFields.fields.push('PhotoSmall')
      }
      const url = getUrlWithParams(requestedItemFields)
      const res = await getFromAPI(url, {}, auth, signal)
      const resp = await res.json();
      return resp;
    }),
    signal,
    select: (data) => {
      if (!data || !data.response) {
        return {}
      }
      return {
        total: data.total,
        items: data.response.map((item, index) => {
          item.key = index;
          if (!item.strName) {
            return item;
          }
          const name =
            item.strName.find((strName) => strName.intLanguageID === langID) ||
            item.strName.find(
              (strName) => strName.intLanguageID === LANGUAGE_ID_DEFAULT
            );
          item.images = [];

          if (item.PhotoSmall && item.PhotoSmall[0]) {
            for (let i = 0; i < item.PhotoSmall.length; i++) {
              const url = `data:${item.PhotoSmall[i].strDataType};charset=utf-8;base64,${item.PhotoSmall[i].picItem}`;
              item.images.push({ url, key: item.PhotoSmall[i].intItemPictureID });
            }
          }
          if(item.ItemPriceAll && item.ItemPriceAll[0]){
            item.prices = {
              displayPrice: item.ItemPriceAll.filter(data => data?.strOrderType?.toLowerCase() === "lieferung"),
              list: item.ItemPriceAll.filter(data => data?.strOrderType?.toLowerCase() !== "lieferung")
            }
          }
          item.dimensions = `${item.decWidth} / ${item.decLength} / ${item.decHeight}`;
          item.name = name && name.strText;
          return item;
        })
      }
    },
    enabled: true,
    refetchOnWindowFocus: false
  })
  const { data: thumbnailData, isLoading: isLoadingThumbnail } = useQuery({
    queryKey: ['items', selectedItem.intItemID],
    queryFn: (async () => {
      const requestedItemFields = {
        fields: []
      }
      if (isItemImageVisible) {
        requestedItemFields.fields.push('PhotoSmall')
      }
      const url = `item/get?requested=${JSON.stringify(requestedItemFields)}&intThumbSize=${encodeURIComponent(500)}&intItemID=${encodeURIComponent(selectedItem.intItemID)}`;
      const res = await getFromAPI(url, {}, auth, signal)
      const resp = await res.json();
      return resp;
    }),
    signal,
    select: (data) => {
      if (!data || !data.response) {
        return {}
      }
      return {
        [selectedItem.intItemID]: data.response.reduce((acc, item) => {
          if (item.PhotoSmall && item.PhotoSmall[0]) {
            for (let i = 0; i < item.PhotoSmall.length; i++) {
              const url = `data:${item.PhotoSmall[i].strDataType};charset=utf-8;base64,${item.PhotoSmall[i].picItem}`;
              acc[item.PhotoSmall[i].intItemPictureID] = url
            }
          }
          return acc;
        }, {})
      }
    },
    enabled: !!isItemImageVisible && !!selectedItem.intItemID
  })

  useEffect(() => {
    if (!isItemImageVisible.updated) {
      company.getCompany().then((data) => {
        if (data && data.isItemThumbnailVisible) {
          setItemImageVisibility({ value: !!data.isItemThumbnailVisible, updated: true })
          setItemsPriceVisibility({ value: !!data.isItemsPriceVisible, updated: true })
        } else {
          setItemImageVisibility((prev) => ({ ...prev, updated: true }))
          setItemsPriceVisibility((prev) => ({ ...prev, updated: true }))
        }
      })
    }
  }, [company])

  const handleSortChange = (sortInfo) => {
    const { field, order } = sortInfo || {}
    setSortedInfo({ field, order })
  }

  const handleNumberChange = change => {
    if (!searchNumber && change.length < 3) {
      return
    }
    // setTableLoading(true)
    if (numberTimer) {
      clearTimeout(numberTimer)
      setNumberTimer(null)
    }
    setNumberTimer(
      setTimeout(() => {
        setSearchNumber(change.length >= 3 ? change : '')
      }, 750)
    )
  }

  const handleNameChange = change => {
    if (!searchName && change.length < 3) {
      return
    }
    // setTableLoading(true)
    if (nameTimer) {
      clearTimeout(nameTimer)
      setNameTimer(null)
    }
    setNameTimer(
      setTimeout(() => {
        setSearchName(change.length >= 3 ? change : '')
      }, 750)
    )
  }

  const handleExport = async () => {
    setExportEnable(false)
    const requestedFields = {
      fields: ['intItemCatID',
        'intItemGrpID',
        'intManufacturerID',
        'strName',
        'strDescription',
        'strItemNo',
        'strColor',
        'strMaterial',
        'strOwner',
        'intUnit',
        'IsFIFOItem',
        'IsLotItem',
        'IsSerialNo',
        'decQtyInBox',
        'decQtyMin',
        'decQtyMax',
        'decQtyInStock',
        'decQtyAvailable',
        'decQtyReserved',
        'decWidth',
        'decLength',
        'decVolume',
        'decHeight']
    }
    const url = getUrlWithParams(requestedFields, 0, data?.total)
    await exportToSheet(t, auth, url)
    setExportEnable(true)
  }

  const updateGroups = async () => {
    await getFromAPI('item/getGroup', {}, auth)
      .then(res => res.json())
      .then(data => {
        if (data.response) {
          setGroups(
            sortByLanguage(
              data.response.map((item, index) => {
                item.key = index
                return item
              }),
              'strName'
            )
          )
        } else {
          setGroups([])
        }
      }).catch(e => setGroups([]))
  }

  // const updateCategories = () => {
  //   getFromAPI('item/getCategory', {}, auth)
  //     .then(res => res.json())
  //     .then(data => {
  //       if (data.response) {
  //         setCategories(
  //           sortByLanguage(
  //             data.response.map((item, index) => {
  //               item.key = index
  //               return item
  //             }),
  //             'strName'
  //           )
  //         )
  //       }
  //     })
  // }

  useEffect(() => {
    updateGroups()
    // updateCategories()
    window.addEventListener('resize', function(event) {
      if((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) > 992 && trigger !== "hover") {
        setTrigger("hover")
      }
      if((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 992 && trigger !== "click") {
        setTrigger("click")
      }
    }, true);
  }, [])

  useEffect(() => {
    setPageOffset(0)
    setPageNumber(1)
  }, [selectedCategory, selectedGroup, searchNumber, searchName, availableItem, isItemImageVisible])

  const columns = [
    {
      title: t('items.itemNumber'),
      dataIndex: 'strItemNo',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.strItemNo.localeCompare(b.strItemNo)
    },
    {
      title: t('items.name'),
      dataIndex: 'name',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.name && a.name.localeCompare(b.name),
      responsive: ['md']
    },
    {
      title: t('items.dimensionsColumn'),
      dataIndex: 'dimensions',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.name && a.name.localeCompare(b.name),
      responsive: ['md']
    },
    {
      title: t('items.image'),
      dataIndex: 'images',
      render: (images, record) => {
        return images && images.length ? <Avatar.Group
          maxCount={images.length > 2 ? 1 : 2}
          size="large"
        >
          {images.slice(0, 3).map((img) => (
            <Popover
              key={img.key}
              content={
                <Spin spinning={isLoadingThumbnail}>
                  <Image width={120} height={120} loading='lazy' src={thumbnailData && thumbnailData[record.intItemID] ? thumbnailData[record.intItemID][img.key] : undefined} placeholder={true} />
                </Spin>
              }
              onVisibleChange={(open) => {
                if (open) {
                  setSelectedItem({
                    intItemID: record.intItemID,
                    intItemPictureID: img.key,
                  })
                }
              }}>
              <Avatar src={img.url} />
            </Popover>
          ))}
        </Avatar.Group> : <></>
      },
      responsive: ['lg'],
      hidden: !isItemImageVisible.value
    },
    {
      title: t('items.available'),
      dataIndex: 'decQtyAvailable',
      defaultSortOrder: 'descend',
      sorter: (a, b) => parseFloat(a.decQtyAvailable) - parseFloat(b.decQtyAvailable),
      responsive: ['md']
    },
    {
      title: t('items.inStock'),
      dataIndex: 'decQtyInStock',
      defaultSortOrder: 'descend',
      sorter: (a, b) => parseFloat(a.decQtyInStock) - parseFloat(b.decQtyInStock),
      responsive: ['md']
    },
    {
      title: t('items.reserved'),
      dataIndex: 'decQtyReserved',
      defaultSortOrder: 'descend',
      sorter: (a, b) => parseFloat(a.decQtyReserved) - parseFloat(b.decQtyReserved),
      responsive: ['md']
    },
    {
      title: t('items.price'),
      key: 'price',
      render: (_, record) => (
        <Prices>
          {record.prices?.displayPrice && record.prices?.displayPrice[0] && <PriceItem>CHF {(record.prices.displayPrice[0].decPrice?.toFixed && record.prices.displayPrice[0].decPrice.toFixed(2)) || '-'}</PriceItem>}
          <Popover trigger={trigger} placement="bottom" content={
            <div>
              {record.prices.list.map((item, i) => <Text key={i}><TruncatedText>{item.strOrderType}</TruncatedText>: <Amount>CHF {(item.decPrice?.toFixed && item.decPrice.toFixed(2)) || '-'}</Amount></Text>)}
            </div>
            }>
              <InfoCircleOutlined />
          </Popover>

        </Prices>
      ),
      hidden: !isItemsPriceVisible.value,
      width: 125
    },
    {
      title: t('items.actions'),
      key: 'action',
      render: (_, record) => (
        <Space size='small'>
          {!selectorMode && hasAccessToRoute('/items/create', auth) && (
            <EditOutlined onClick={() => setEditItem(record)} />
          )}
          {/* {!selectorMode && <DeleteOutlined />} */}
          <InfoCircleOutlined onClick={() => setPreviewItem(record)} />
          {selectorMode && (
            <FileAddOutlined onClick={() => onSelect && onSelect(record)} />
          )}
        </Space>
      )
    }
  ].filter(col => !col.hidden)

  const ItemPageWrapper = selectorMode ? SelectorLayout : MainLayout
  return (
    <ItemPageWrapper $selectorMode={selectorMode}>
      <SearchBar>
        <Form.Item name='number' label={t('items.itemNumber')} colon={false}>
          <Input
            onChange={e => handleNumberChange(e.target.value)}
            type='text'
          />
        </Form.Item>

        <Form.Item name='intItemGrpID' label={t('items.group')} colon={false}>
          <Select
            showSearch
            allowClear
            placeholder={t('items.selectPlaceholder')}
            optionFilterProp='children'
            onSelect={item => setSelectedGroup(item)}
            onClear={() => setSelectedGroup()}
            style={{ minWidth: 150 }}
            popupMatchSelectWidth={false}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {groups?.map((group, index) => {
              const text =
                group.strName.find(name => name.intLanguageID === langID) ||
                group.strName.find(
                  name => name.intLanguageID === LANGUAGE_ID_DEFAULT
                )
              return (
                <Option key={index} value={group.intItemGrpID}>
                  {text ? text.strText : `${group.intItemGrpID}`}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item name='name' label={t('items.name')} colon={false}>
          <Input
            onChange={e => handleNameChange(e.target.value)}
            type='text'
          />
        </Form.Item>
        {(auth?.token?.role && !READ_ONLY_ROLES.includes(auth.token.role)) && <Checkbox style={{ lineHeight: '32px', height: '32px' }} checked={availableItem} onChange={(e) => setAvailableItem(e.target.checked)}>
          {t('items.availableItems')}
        </Checkbox>}
        {/* <Form.Item
          name='intItemCatID'
          label={t('items.category')}
          colon={false}
        >
          <Select
            showSearch
            allowClear
            placeholder={t('items.selectPlaceholder')}
            optionFilterProp='children'
            style={{ minWidth: 150 }}
            onSelect={item => setSelectedCategory(item)}
            onClear={() => setSelectedCategory()}
            popupMatchSelectWidth={false}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {categories.map((category, index) => {
              const text =
                category.strName.find(name => name.intLanguageID === langID) ||
                category.strName.find(
                  name => name.intLanguageID === LANGUAGE_ID_DEFAULT
                )
              return (
                <Option key={index} value={category.intItemCatID}>
                  {text ? text.strText : `${category.intItemCatID}`}
                </Option>
              )
            })}
          </Select>
        </Form.Item> */}
      </SearchBar>
      <Table
        columns={columns}
        dataSource={data?.items || []}
        showSorterTooltip={false}
        onChange={(_, __, sortInfo) => handleSortChange(sortInfo)}
        loading={isLoading}
        scroll={{ x: "scroll" }}
        onRow={record => {
          return {
            onDoubleClick: () => {
              onSelect && onSelect(record)
            }
          }
        }}
        pagination={{
          total: data?.total || 0,
          current: pageNumber,
          pageSize,
          onChange: (pageNumber, size) => {
            setPageOffset(pageNumber > 1 ? (pageNumber - 1) * size : 0);
            setPageNumber(pageNumber);
          },
          onShowSizeChange: (_, size) => setPageSize(size),
        }}
      />
      <StyledSpace $emptyTable={data?.items?.length === 0}>
        <Button disabled={!exportEnable} onClick={handleExport}>{t('items.export')}</Button>
      </StyledSpace>
      <ItemModal
        visible={editItem || previewItem}
        previewMode={previewItem}
        afterClose={() => {
          setEditItem()
          setPreviewItem()
        }}
        onSubmit={() => {
          setEditItem()
          setPreviewItem()
          refetch()
        }}
        values={editItem || previewItem}
        title={editItem ? t('items.editItem') : t('items.previewItem')}
      />
    </ItemPageWrapper>
  )
}

const SearchBar = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  @media ${device.tablet} {
    flex-direction: row;
  }
  @media ${device.laptopM} {
    justify-content: space-evenly;
    flex-wrap: nowrap;
  }
  width: 100%;
  margin-bottom: 10px;
`

const SelectorLayout = styled.div``

const StyledSpace = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-top: ${props => (props.$emptyTable ? '10px' : '-50px')};
`

const TruncatedText = styled.span`
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block
`

const Amount = styled.span`
  font-weight: 600;
  margin-left: 2px;
`

const Prices = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`

const PriceItem = styled.span`
  font-size: 13px;
`

const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`
