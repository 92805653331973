
// Secondary, borders, background, highlight for links on red background
const yellow = '#fc0'
// Primary, button background, borders, highlight color for links
const red = '#942642'
// Font color
const grey = '#737570'
// Font color on red background
const white = '#fff'
const black = '#000000D9'

export const colors = {
  primary: red,
  secondary: yellow,
  yellow: yellow,
  red: red,
  grey: grey,
  white: white,
  black: black,
}
